// third-party
import {FormattedMessage} from 'react-intl';

// assets
import { DirectNotification, HomeTrendUp, Notification1, SecurityCard, TicketStar } from 'iconsax-react';

// type
import {NavItemType} from '../../../atoms/types/menu';
import React from 'react';

const dashboard: NavItemType = {
  id: 'group-dashboard',
  title:  <FormattedMessage id="menu.home" />,
  type: 'group',
  children: [
    {
      id: 'tasks',
      title: <FormattedMessage id="menu.tasks" />,
      type: 'item',
      url: '/tasks',
      icon: DirectNotification,
      breadcrumbs: true
    },
    {
      id: 'vog',
      title: <FormattedMessage id="menu.vog" />,
      type: 'item',
      url: '/vog',
      icon: SecurityCard,
      breadcrumbs: true
    }
  ]
};

export default dashboard;
