import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, defaultGenericState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders, getErrorCodeFromMessage } from '../slice';
import { getVogRequest, getVogRequests, getVogRequestsByEmployee, resendShareVogEmail, saveVogRequest } from './VogRequestApi';
import { ScreeningsProfiel } from '../staticdata';
import { Vog } from '../vog';


export interface VogRequestActionHistory {
    action: string;
    changedAt: Date;
}

export interface VogRequest {
    id: string | undefined;
    employeeId: string | undefined;
    emailAddress: string | undefined;
    geslachtsnaam: string | undefined;
    tussenvoegsels: string | undefined;
    voornamen: string | undefined;
    geboortedatum: Date | undefined;
    geboorteplaats: string | undefined;
    geboorteland: string | undefined;
    status: string | undefined;
    allowedNewStatusses: string[] | undefined;
    statusDate: Date | undefined;
    functionProfileId: number | undefined;
    screeningsProfielType: string | undefined;
    specifiekProfielFunctieAspectNr: string | undefined;
    specifiekFunctieAspectOmschrijving: string | undefined;
    algemeenScreeningsProfielFuncties: ScreeningsProfiel[] | undefined;
    actionHistory: VogRequestActionHistory[] | undefined;
    vogId: string | undefined;
}

export interface VogRequestState extends GenericPageableState<VogRequest> {
    statusCounts: any;
}

export const defaultVogRequestState: VogRequestState = {
    ...defaultGenericPagableState,
    statusCounts: {}
}

export const vogRequestSlice = createSlice({
    name: 'vogRequest',
    initialState: defaultVogRequestState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getVogRequestsByEmployee);
        addGenericPageableStateSingleBuilders(builder, getVogRequest);
        addGenericPageableStateSingleBuilders(builder, saveVogRequest);
        addGenericPageableStateSingleBuilders(builder, resendShareVogEmail);
        builder.addCase(getVogRequests.pending.type, (state: VogRequestState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
                errorCode: undefined
            };
        });
        builder.addCase(getVogRequests.fulfilled.type, (state: VogRequestState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                errorCode: undefined,
                list: Object.assign([], action.payload.vogRequests.content),
                statusCounts: action.payload.statusCounts,
                currentPage: action.payload.vogRequests.number,
                totalPages: action.payload.vogRequests.totalPages,
                totalElements: action.payload.vogRequests.totalElements,
                isLastPage: action.payload.vogRequests.last,
                pageSize: action.payload.vogRequests.size
            };
        });
        builder.addCase(getVogRequests.rejected.type, (state: VogRequestState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
                errorCode: getErrorCodeFromMessage(action.error.message),
            };
        });

    },
});


export function getLastVogActionHistory(vogRequest: VogRequest, action: string): VogRequestActionHistory | undefined {
    if (vogRequest.actionHistory) {
        return vogRequest.actionHistory
            .filter(taskHistory => taskHistory.action === action)
            .sort((e1, e2) => new Date(e2.changedAt).getTime() - new Date(e1.changedAt).getTime())
            .shift();
    }
    return;
}
