import React, { FC, useEffect, useState } from 'react';
import { authSelector, EmployeeForm, employeeSelector, getEmployee, saveEmployee, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DateInput, ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';


export const EmployeeEditDetailContent: FC = () => {
    const [employeeForm, setEmployeeForm] = useState<EmployeeForm>(new EmployeeForm());
    const dispatch = useAppDispatch();
    const {employeeId} = useParams();
    const employeeState = useSelector(employeeSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);


    useEffect(() => {
        if (employeeId) {
            dispatch(getEmployee({jwtToken: authState.jwtToken!, employeeId: employeeId}));
        }
    }, [dispatch, employeeId, authState.jwtToken]);

    useEffect(() => {
        if (employeeId && employeeState.singleItem && employeeState.singleItem.id) {
            setEmployeeForm(EmployeeForm.from(employeeState.singleItem));
        }
    }, [employeeState.singleItem, employeeId]);

    const handleSetFormValue = (param: any) => {
        setEmployeeForm(Object.assign({}, employeeForm, param));
    }


    function saveForm() {
        dispatch(saveEmployee({jwtToken: authState.jwtToken!, employeeForm: employeeForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate(-1)
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return EmployeeForm.isValid(employeeForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={employeeState.error !== undefined}/>
            <h2>{employeeId ? t('fields.employee.single.edit') : t('fields.employee.single.new')}</h2>
            <MainCard>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="emailAddress">{t('fields.employee.single.emailAddress')}</InputLabel>
                                <TextField rows={1} value={employeeForm.emailAddress}
                                           onChange={e => handleSetFormValue({emailAddress: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geslachtsnaam">{t('fields.employee.single.geslachtsnaam')}</InputLabel>
                                <TextField rows={1} value={employeeForm.geslachtsnaam}
                                           onChange={e => handleSetFormValue({geslachtsnaam: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="tussenvoegsels">{t('fields.employee.single.tussenvoegsels')}</InputLabel>
                                <TextField rows={1} value={employeeForm.tussenvoegsels}
                                           onChange={e => handleSetFormValue({tussenvoegsels: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="voornamen">{t('fields.employee.single.voornamen')}</InputLabel>
                                <TextField rows={1} value={employeeForm.voornamen}
                                           onChange={e => handleSetFormValue({voornamen: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geboortedatum">{t('fields.employee.single.geboortedatum')}</InputLabel>
                                <DateInput value={employeeForm?.geboortedatum} onChange={(value) => handleSetFormValue({geboortedatum: value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geboorteplaats">{t('fields.employee.single.geboorteplaats')}</InputLabel>
                                <TextField rows={1} value={employeeForm.geboorteplaats}
                                           onChange={e => handleSetFormValue({geboorteplaats: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geboorteland">{t('fields.employee.single.geboorteland')}</InputLabel>
                                <TextField rows={1} value={employeeForm.geboorteland}
                                           onChange={e => handleSetFormValue({geboorteland: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="notes">{t('fields.employee.single.notes')}</InputLabel>
                                <TextField multiline maxRows={5} minRows={2} value={employeeForm.notes}
                                           onChange={e => handleSetFormValue({notes: e.target.value})}/>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 4}}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm} disabled={employeeState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};

