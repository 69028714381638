import isEmail from "validator/lib/isEmail";
import { Employee } from './EmployeeSlice';

export class EmployeeForm {
    id: string | undefined;
    emailAddress: string | undefined;
    geslachtsnaam: string | undefined;
    tussenvoegsels: string | undefined;
    voornamen: string | undefined;
    geboortedatum: Date | undefined;
    geboorteplaats: string | undefined;
    geboorteland: string | undefined;

    notes: string | undefined;

    static from(employee: Employee): EmployeeForm {
        return {
            id: employee.id,
            emailAddress: employee.emailAddress,
            geslachtsnaam: employee.geslachtsnaam,
            tussenvoegsels: employee.tussenvoegsels,
            voornamen: employee.voornamen,
            geboortedatum: employee.geboortedatum,
            geboorteplaats: employee.geboorteplaats,
            geboorteland: employee.geboorteland,
            notes: employee.notes,
        }
    }

    static isValid(employeeForm: EmployeeForm | undefined): boolean {
        if (employeeForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(employeeForm.emailAddress)) {
            return false;
        }

        if (!isEmail(employeeForm.emailAddress!)) {
            return false;
        }

        if (!containsValue(employeeForm.geslachtsnaam)) {
            return false;
        }
        if (!containsValue(employeeForm.voornamen)) {
            return false;
        }

        if (!containsValue(employeeForm.geboortedatum)) {
            return false;
        }

        return true;
    }

}

