import { FunctionProfile } from './FunctionProfileSlice';
import { ScreeningsProfiel } from '../staticdata';

export class FunctionProfileForm {
    id: number | undefined;
    name: string | undefined;
    screeningsProfiles: ScreeningsProfiel[] | undefined;

    static from(functionProfile: FunctionProfile): FunctionProfileForm {
        return {
            id: functionProfile.id,
            name: functionProfile.name,
            screeningsProfiles: functionProfile.screeningsProfiles
        }
    }

    static isValid(functionProfileForm: FunctionProfileForm | undefined): boolean {
        if (functionProfileForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(functionProfileForm.name)) {
            return false;
        }

        if (functionProfileForm.screeningsProfiles?.length === 0) {
            return false;
        }

        return true;
    }

}

