import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { EmployeeForm } from './EmployeeForm';
import { formatDateForTransport } from '../DateUtils';

export const getEmployees = createAsyncThunk(
        'employee/getEmployees', ({jwtToken, page, pageSize}: {jwtToken: string | undefined,  page: number, pageSize: number}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));

            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.get('/manage/employees?page=' + page + '&size=' + pageSize, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
export const getEmployee = createAsyncThunk(
    'employee/getEmployee', ({jwtToken, employeeId}: { jwtToken: string | undefined, employeeId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/employees/' + employeeId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const saveEmployee = createAsyncThunk(
    'employee/saveEmployee', ({jwtToken, employeeForm}: { jwtToken: string | undefined, employeeForm: EmployeeForm }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const body = {
            id: employeeForm.id,
            emailAddress: employeeForm.emailAddress,
            geslachtsnaam: employeeForm.geslachtsnaam,
            tussenvoegsels: employeeForm.tussenvoegsels,
            voornamen: employeeForm.voornamen,
            geboortedatum: formatDateForTransport(employeeForm.geboortedatum),
            geboorteplaats: employeeForm.geboorteplaats,
            geboorteland: employeeForm.geboorteland,
            notes: employeeForm.notes

        }
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.post('/manage/employees', body, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const deleteEmployee = createAsyncThunk(
    'employee/deleteEmployee', ({jwtToken, employeeId}: { jwtToken: string | undefined, employeeId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.delete('/manage/employees/' + employeeId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
