import React, {FC} from 'react';
import {t} from 'i18next';
import {ListItemText, MenuItem, Select} from '@mui/material';
import { FunctionProfile } from '../../state';

interface Props {
    selectedFunctionProfileId?: string;
    onSelect: (functionProfile: FunctionProfile) => void;
    functionProfiles?: FunctionProfile[];
    disabled?: boolean;
}


export const FunctionProfileSelectCombo: FC<Props> = (props) => {
    function onSelectItem(functionProfileId: string) {
        props.onSelect(props.functionProfiles!.filter((functionProfile) => '' + functionProfile.id === functionProfileId)[0]);
    }

    function getFunctionProfileLabel(functionProfileId: string):string {
        const functionProfile = props.functionProfiles!.filter((functionProfile) => '' + functionProfile.id === functionProfileId)[0];
        return (functionProfile) ? functionProfile.name : '';
    }

    return (
        <Select value={props.selectedFunctionProfileId ? props.selectedFunctionProfileId : ''}
                displayEmpty
                onChange={e => onSelectItem(e.target.value)}
                disabled={props.disabled}
                renderValue={(selected) => {
                    if (!selected) {
                        return <ListItemText primary={t('fields.service.single.selectA')}/>;
                    }

                    return <ListItemText primary={getFunctionProfileLabel(selected)}/>;
                }}>
            {props.functionProfiles?.map((functionProfile) => (
                <MenuItem key={'' + functionProfile.id} value={'' + functionProfile.id}>
                    <ListItemText primary={functionProfile.name} />
                </MenuItem>))}
        </Select>
    )
};

FunctionProfileSelectCombo.defaultProps = {
    disabled: false
}
