import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders } from '../slice';
import { deleteEmployee, getEmployee, getEmployees, saveEmployee } from './EmployeeApi';
import { VogRequestForm } from '../vogrequest';


export interface Employee {
    id: string | undefined;
    emailAddress: string | undefined;
    geslachtsnaam: string | undefined;
    tussenvoegsels: string | undefined;
    voornamen: string | undefined;
    geboortedatum: Date | undefined;
    geboorteplaats: string | undefined;
    geboorteland: string | undefined;
    notes: string | undefined;
}

export interface EmployeeState extends GenericPageableState<Employee> {
}

export const employeeSlice = createSlice({
    name: 'vog',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getEmployees);
        addGenericPageableStateSingleBuilders(builder, getEmployee);
        addGenericPageableStateSingleBuilders(builder, saveEmployee);
        addGenericPageableStateSingleBuilders(builder, deleteEmployee);
    },
});

export const getFullNameFromEmployee = (employee: Employee | undefined): string => {
    return getFullName(employee?.geslachtsnaam, employee?.voornamen, employee?.tussenvoegsels);
}
export const getFullName = (geslachtsnaam: string | undefined, voornamen: string | undefined, tussenvoegsels: string | undefined): string => {
    return geslachtsnaam + ", " + voornamen + ' ' + ((tussenvoegsels === undefined) ? '' : tussenvoegsels);
}
export const getFullNameInReadableFormatFromVogRequest = (vogRequest: VogRequestForm): string => {
        return getFullNameInReadableFormat(vogRequest.geslachtsnaam, vogRequest.voornamen, vogRequest.tussenvoegsels);
}
export const getFullNameInReadableFormat = (geslachtsnaam: string | undefined, voornamen: string | undefined, tussenvoegsels: string | undefined): string => {
        return voornamen + " " + (tussenvoegsels ? tussenvoegsels + ' ' : '') + geslachtsnaam;
}
