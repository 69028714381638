import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateForDisplay, functionProfileSelector, getFunctionProfiles, getVogRequests, useAppDispatch, VogRequest, vogRequestSelector } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable, MRT_PaginationState, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, Tab, Tabs } from '@mui/material';


interface Props {
}

export const VogListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const vogRequests = useSelector(vogRequestSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const functionProfiles = useSelector(functionProfileSelector);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [statuses, setStatuses] = useState<string[]>(['NEW',
        'REQUESTED', 'CANCELLED', 'VOG_RECEIVED_NO_FULL_MATCH', 'REJECTED', 'ACCEPTED'])
    const groups = ['ALL', ...statuses];
    const [activeTab, setActiveTab] = useState(groups[0]);

    useEffect(() => {
        if (authState.jwtToken) {
            dispatch(getFunctionProfiles({jwtToken: authState.jwtToken!, page: 0, pageSize: 100}));
        }
    }, [authState.jwtToken]);
    useEffect(() => {
        dispatch(getVogRequests({jwtToken: authState.jwtToken, status: activeTab, page: pagination.pageIndex, pageSize: pagination.pageSize}));
        // eslint-disable-next-line
    }, [authState.jwtToken, activeTab, pagination.pageIndex, pagination.pageSize]);

    const columns = useMemo<MRT_ColumnDef<VogRequest>[]>(
        () => [
            {
                header: t('fields.employee.single.geslachtsnaam'),
                accessorKey: 'geslachtsnaam',
            },
            {
                header: t('fields.employee.single.tussenvoegsels'),
                accessorKey: 'tussenvoegsels',
            },
            {
                header: t('fields.employee.single.voornamen'),
                accessorKey: 'voornamen',
            },            {
                header: t('fields.vog.single.functieProfiel'),
                accessorFn: (originalRow) => functionProfiles.list.filter(value => value.id === originalRow.functionProfileId).map(value => value.name),
                size: 50
            },
            {
                header: t('fields.vog.single.geboortedatum'),
                accessorFn: (originalRow) => formatDateForDisplay(originalRow.geboortedatum),
            },
            {
                header: t('fields.vogRequest.single.status'),
                accessorFn: (originalRow) => t('fields.vogRequest.status.' + originalRow.status),
            }
        ],
        [],
    );

    function navigateTo(vogRequest: VogRequest) {
        console.log('QQQ vogRequest: ', vogRequest);
        switch (vogRequest.status) {

            case 'NEW':
                navigate('/vogrequest/' + vogRequest.employeeId + '/' + vogRequest?.id);
                break;
            case 'REQUESTED':
                navigate('/vogrequest/' + vogRequest.employeeId + '/' + vogRequest?.id);
                break;
            case 'VOG_RECEIVED':
                navigate('/vogrequest/' + vogRequest.employeeId + '/' + vogRequest?.id);
                break;
            case 'REJECTED':
                navigate('/vogrequest/' + vogRequest.employeeId + '/' + vogRequest?.id);
                break;
            case 'CANCELLED':
                navigate('/vogrequest/' + vogRequest.employeeId + '/' + vogRequest?.id);
                break;
            case 'VOG_RECEIVED_NO_FULL_MATCH':
                navigate('/vog/' + vogRequest.vogId + '/judgeDifferences');
                break;
            case 'ACCEPTED':
                navigate('/vog/' + vogRequest.vogId);
                break;
        }
    }

    const table = useMaterialReactTable({
        columns,
        data: vogRequests.list,
        rowCount: vogRequests.totalElements,
        pageCount: vogRequests.totalPages,
        manualPagination: true,
        onPaginationChange: setPagination,
        enableRowSelection: false,
        enableColumnOrdering: false,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        state: { pagination },
        muiTableBodyCellProps: ({column, row }) => ({
            onClick: (event) => {
                if (column.id !== 'mrt-row-actions') {
                    navigateTo(row.original);
                }
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
    });

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={vogRequests.error !== undefined}/>
            <h2>{t('fields.vog.plural.typeDescription')}</h2>

            <Box sx={{ p: 3, pb: 0, width: '100%' }}>
                <Tabs
                    value={activeTab}
                    onChange={(e: ChangeEvent<{}>, value: string) => setActiveTab(value)}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    {groups.map((status: string, index: number) => (
                        <Tab
                            key={index}
                            label={t('fields.vogRequest.status.' + status)}
                            value={status}
                            icon={
                                <Chip
                                    label={
                                        status === 'ALL'
                                            ? Object.keys(vogRequests.statusCounts).reduce((result: number, current: string) => result + vogRequests.statusCounts[current], 0)
                                            : vogRequests.statusCounts[status]??0
                                    }
                                    color='primary'
                                    variant="light"
                                    size="small"
                                />
                            }
                            iconPosition="end"
                        />
                    ))}
                </Tabs>
            </Box>
            <MaterialReactTable table={table} />

        </Container>
    )
};
