import React, { FC, useEffect, useState } from 'react';
import { SCREENINGS_PROFIEL_TYPE_ALGEMEEN, SCREENINGS_PROFIEL_TYPE_SPECIFIEK } from '../../state';
import { t } from 'i18next';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';


interface Props {
    selectedScreeningsProfielType: string | undefined;
    onChange: (screeningsProfielType: string) => void;
    disabled?: boolean;
}


export const ScreeningsProfielTypeRadioGroup: FC<Props> = (props) => {
    const [screeningsProfielType, setScreeningsProfielType] = useState<string>(SCREENINGS_PROFIEL_TYPE_SPECIFIEK);


    useEffect(() => {
        setScreeningsProfielType(props.selectedScreeningsProfielType!);
    }, [props.selectedScreeningsProfielType]);

    const onSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = ((event.target as HTMLInputElement).value);
        setScreeningsProfielType(selectedValue);
        props.onChange(selectedValue);
    };

    return (
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group-screenings-profiel"
            value={screeningsProfielType}
            onChange={onSelectItem}

            row>
            <FormControlLabel disabled={props.disabled} value={SCREENINGS_PROFIEL_TYPE_SPECIFIEK} control={<Radio/>} label={t('fields.vogScreeningsProfielType.specifiek')}/>
            <FormControlLabel disabled={props.disabled} value={SCREENINGS_PROFIEL_TYPE_ALGEMEEN} control={<Radio/>} label={t('fields.vogScreeningsProfielType.algemeen')}/>
        </RadioGroup>
    )
};

ScreeningsProfielTypeRadioGroup.defaultProps = {
    selectedScreeningsProfielType: SCREENINGS_PROFIEL_TYPE_SPECIFIEK
}



