import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';

export const getTasks = createAsyncThunk(
        'vog/getTasks', ({jwtToken, page, pageSize}: {jwtToken: string | undefined,  page: number, pageSize: number}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.get('/manage/tasks?page=' + page + '&size=' + pageSize, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
