import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateForDisplay, getFullNameFromEmployee, getTaskDescription, getTasks, resendShareVogEmail, Task, taskSelector, useAppDispatch, } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert, TaskActionButton } from '../../molecules';
import { t } from 'i18next';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';


interface Props {
}


export const TaskListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const tasks = useSelector(taskSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });

    const columns = useMemo<MRT_ColumnDef<Task>[]>(
        () => [
            {
                header: t('fields.employee.single.fullName'),
                accessorFn: (originalRow) => getFullNameFromEmployee(originalRow.employee),
                size: 200
            },
            {
                header: t('fields.employee.single.geboortedatum'),
                accessorFn: (originalRow) => formatDateForDisplay(originalRow.employee?.geboortedatum),
                size: 50
            },
            {
                header: t('screens.tasks.description.title'),
                accessorFn: (originalRow) => getTaskDescription(originalRow),
                size: 400,
            }
        ],
        [],
    );
    useEffect(() => {
        dispatch(getTasks({jwtToken: authState.jwtToken, page: pagination.pageIndex, pageSize: pagination.pageSize}));
        // eslint-disable-next-line
    }, [authState.jwtToken, pagination.pageIndex, pagination.pageSize]);

    function sendShareVogEmail(vogRequestId: string | undefined) {
        if (vogRequestId) {
            dispatch(resendShareVogEmail({jwtToken: authState.jwtToken!, vogRequestId: vogRequestId}))
                .then((response) => {
                    if (response.type.includes('fulfilled')) {
                        enqueueSnackbar(t('screens.tasks.shareVogEmailSent', {email: response.payload.emailAddress}), {variant: 'success'});
                        dispatch(getTasks({jwtToken: authState.jwtToken, page: pagination.pageIndex, pageSize: pagination.pageSize}));
                    }
                });
        }

    }

    function performAction(task: Task) {
        switch (task.taskType) {
            case 'ALMOST_EXPIRED_VOG':
                navigate('/vogrequest/' + task.employee?.id + '/add');
                break;
            case 'VOG_BEING_REQUESTED':
                sendShareVogEmail(task.vogRequest?.id);
                break;
            case 'UNMATCHED_VOG_RECEIVED':
                navigate('/vog/' + task.vog?.id + '/judgeDifferences');
                break;
            case 'VOG_REQUEST_MISSING':
                navigate('/vogrequest/' + task.employee?.id + '/add');
                break;
            case 'VOG_REQUEST_WITH_STATUS_NEW':
                navigate('/vogrequest/' + task.employee?.id + '/' + task.vogRequest?.id);
                break;
        }

    }

    const table = useMaterialReactTable({
        columns,
        data: tasks.list,
        rowCount: tasks.totalElements,
        pageCount: tasks.totalPages,
        manualPagination: true,
        onPaginationChange: setPagination,
        enableRowSelection: false,
        enableColumnOrdering: false,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        state: {pagination},
        enableRowActions: true,
        positionActionsColumn: "last",
        localization: MRT_Localization_NL,
        renderRowActions: ({row, table}) => (<TaskActionButton task={row.original} onActionSelected={task => {
                performAction(task);
            }}></TaskActionButton>
        ),
        muiTableBodyCellProps: ({column, row}) => ({
            onClick: (event) => {
                if (column.id !== 'mrt-row-actions') {
                    navigate('/employees/' + row.original.employee!.id);
                }
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
    });


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={tasks.error !== undefined}/>
            <h2>{t('fields.task.plural.typeDescription')}</h2>
            <MaterialReactTable table={table}/>
        </Container>
    )


};
