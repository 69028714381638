import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';

export const getVogs = createAsyncThunk(
        'vog/getVogs', ({jwtToken, page, pageSize}: {jwtToken: string | undefined,  page: number, pageSize: number}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.get('/manage/vog?page=' + page + '&size=' + pageSize, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
export const getVogsForEmployee = createAsyncThunk(
        'vog/getVogsForEmployee', ({jwtToken, employeeId, page, pageSize}: {jwtToken: string | undefined, employeeId: string | undefined, page: number, pageSize: number}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.get('/manage/vog/employee/' + employeeId + '?page=' + page + '&size=' + pageSize, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);

export const getVog = createAsyncThunk(
    'vog/getVog', ({jwtToken, vogId}: {jwtToken: string | undefined,  vogId: string | undefined}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/vog/' + vogId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
