// project-imports
import referenceData from './reference-data';

// types
import { NavItemType } from '../../../atoms/types/menu';
import dashboard from './dashboard';
import manage from './manage';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, manage]
};

export default menuItems;
