import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Container } from "react-bootstrap";
import { Provider } from "react-redux";
import { store } from "./state";
import { AppInit } from "./components/AppInit";
import { configureAxiosDefaults } from './AxiosConfig';
import AuthenticationStateHandler from './components/organisms/AuthenticationStateHandler';
import { I18n } from './components/I18n';
import {
    AuthenticationExpiredContent,
    EmployeeEditDetailContent,
    EmployeeListContent,
    EmployeeViewDetailContent, FunctionProfileEditDetailContent,
    FunctionProfileListContent,
    LoginAuthenticateContent,
    LoginPrepareContent, TaskJudgeVogDifferencesContent,
    TaskListContent,
    VogListContent,
    VogRequestDetailContent, VogViewDetailContent
} from "./components/organisms";
import ThemeCustomization from './themes';
import CommonLayout from './components/templates/CommonLayout';
import MainLayout from './components/templates/MainLayout';
import Locales from './components/molecules/Locales';
import { SignupFormContent } from "./components/organisms/signup/SignupFormContent";
import { SignupCheckEmailContent } from "./components/organisms/signup/SignupCheckEmailContent";
import { SignupCompletedContent } from "./components/organisms/signup/SignupCompleted";
import { SignupConfirmContent } from "./components/organisms/signup/SignupConfirm";
import { ExternalRedirect } from "./components/molecules/ExternalRedirect";
import { SnackbarProvider } from 'notistack';
import { Loader } from "./components/molecules/Loader";
import { AppAuthenticatedInit } from './components/AppAuthenticatedInit';


function App() {
    configureAxiosDefaults(store);
    return (
        <Provider store={store}>
            <AppInit/>
            <I18n/>
            <SnackbarProvider autoHideDuration={5000} />
            <BrowserRouter>
                <ThemeCustomization>
                    <Locales>
                <Container fluid className="px-0">
                    <Loader/>
                    <AuthenticationStateHandler>
                        <AuthenticationStateHandler.Loading>
                            <div>Loading</div>
                        </AuthenticationStateHandler.Loading>
                        <AuthenticationStateHandler.AuthenticationExpired>
                            <Routes>
                                <Route path="/" element={<CommonLayout layout={'header-footer-only'}/>} >
                                    <Route path="/" element={<AuthenticationExpiredContent/>}/>
                                    <Route path="*" element={<AuthenticationExpiredContent/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.AuthenticationExpired>
                        <AuthenticationStateHandler.UnAuthenticated>
                            <Routes >
                                <Route path="/" element={<CommonLayout />}>
                                    <Route path="/" element={<LoginPrepareContent/>}/>
                                    <Route path="/register"  element={<ExternalRedirect url="https://www.satisfeed.nl/prijzen" /> }/>
                                    <Route path="authenticate" element={<LoginAuthenticateContent/>}/>
                                    <Route path="/signup" element={<CommonLayout layout={'header-footer-only'}/>}>
                                        <Route path="/signup/checkemail" element={<SignupCheckEmailContent/>}/>
                                        <Route path="/signup/confirm/:signupRequestExternalKey" element={<SignupConfirmContent/>}/>
                                        <Route path="/signup/completed" element={<SignupCompletedContent/>}/>
                                        <Route path="/signup/:subscriptionType" element={<SignupFormContent/>}/>
                                    </Route>
                                    <Route path="*" element={<LoginPrepareContent/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.UnAuthenticated>
                        <AuthenticationStateHandler.Authenticated>
                            <AppAuthenticatedInit/>
                            <Routes>
                                <Route path="/" element={<MainLayout />}>
                                    <Route path="/" element={<TaskListContent/>}/>
                                    <Route path="/vog" element={<VogListContent/>}/>
                                    <Route path="/vog/:vogId" element={<VogViewDetailContent/>}/>
                                    <Route path="/employees/add" element={<EmployeeEditDetailContent/>}/>
                                    <Route path="/employees/edit/:employeeId" element={<EmployeeEditDetailContent/>}/>
                                    <Route path="/employees/:employeeId" element={<EmployeeViewDetailContent/>}/>
                                    <Route path="/employees" element={<EmployeeListContent/>}/>
                                    <Route path="/vogrequest/:employeeId/add" element={<VogRequestDetailContent/>}/>
                                    <Route path="/vogrequest/:employeeId/:vogRequestId" element={<VogRequestDetailContent/>}/>
                                    <Route path="/functionprofiles" element={<FunctionProfileListContent/>}/>
                                    <Route path="/functionprofiles/add" element={<FunctionProfileEditDetailContent/>}/>
                                    <Route path="/functionprofiles/edit/:functionProfileId" element={<FunctionProfileEditDetailContent/>}/>
                                    <Route path="/vog/:vogId/judgeDifferences" element={<TaskJudgeVogDifferencesContent/>}/>
                                    <Route path="/tasks" element={<TaskListContent/>}/>
                                    <Route path="*" element={<TaskListContent/>}/>
                                </Route>
                            </Routes>
                        </AuthenticationStateHandler.Authenticated>
                    </AuthenticationStateHandler>
                </Container>
                    </Locales>
                </ThemeCustomization>
            </BrowserRouter>
       </Provider>
    );
}

export default App;
