import React, { FC, useEffect } from 'react';
import { authSelector, deleteEmployee, Employee, employeeSelector, formatDateForDisplay, getEmployee, getFullNameFromEmployee, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, Stack } from '@mui/material';
import { ButtonConfirm, LabelValue } from '../../atoms';
import { EmployeeVogRequestTable } from './EmployeeVogRequestTable';
import { EmployeeVogTable } from './EmployeeVogTable';


export const EmployeeViewDetailContent: FC = () => {
    const dispatch = useAppDispatch();
    const {employeeId} = useParams();
    const employeeState = useSelector(employeeSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);

    useEffect(() => {
        if (employeeId) {
            dispatch(getEmployee({jwtToken: authState.jwtToken!, employeeId: employeeId}));
        }
    }, [dispatch, employeeId, authState.jwtToken]);

    function edit() {
        navigate('/employees/edit/' + employeeId);
    }

    function deleteEmp(employee: Employee | undefined) {
        if (employee && employee.id) {
            dispatch(deleteEmployee({jwtToken: authState.jwtToken!, employeeId: employee.id}))
                .then((value) => {
                        if (value.type.includes('fulfilled')) {
                            navigate(-1);
                        }
                    }
                );
        }

    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={employeeState.error !== undefined}/>
            <h2>{t('fields.employee.single.typeDescription')}</h2>
            <MainCard>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <LabelValue label={t('fields.employee.single.geslachtsnaam')} value={getFullNameFromEmployee(employeeState.singleItem)}/>
                            <LabelValue label={t('fields.employee.single.emailAddress')} value={employeeState.singleItem?.emailAddress}/>
                            <LabelValue label={t('fields.employee.single.geboortedatum')} value={formatDateForDisplay(employeeState.singleItem?.geboortedatum)}/>
                            <LabelValue label={t('fields.employee.single.notes')} value={employeeState.singleItem?.notes}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 4}}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={edit} disabled={employeeState.loading}>{t('generic.edit')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
            <MainCard title={t('fields.vogRequest.plural.typeDescription')} sx={{mt:2}}>
                <Grid item xs={12} md={8}>
                    <EmployeeVogRequestTable employeeId={employeeId}/>
                </Grid>
            </MainCard>
            <MainCard title={t('fields.vog.plural.typeDescription')} sx={{mt:2}}>
                <Grid item xs={12} md={8}>
                    <EmployeeVogTable employeeId={employeeId}/>
                </Grid>
            </MainCard>

            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={2}>
                {/*<ButtonConfirm variant="outlined" color="secondary" title={t('employee.deleteConfirmDialogTitle')} bodyText={t('employee.deleteConfirmDialogBody')}  onConfirm={() => deleteEmp(employeeState.singleItem)}>{t('generic.delete')}</ButtonConfirm>*/}
                <ButtonConfirm variant="outlined" color="secondary" title={t('screens.employee.deleteConfirmDialogTitle')} bodyText={t('screens.employee.deleteConfirmDialogBody')} onConfirm={() => deleteEmp(employeeState.singleItem)}
                               buttonLabel={t('generic.delete')}/>
            </Stack>
        </Container>
    )


};

