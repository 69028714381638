import React, { FC, useEffect } from 'react';
import { authSelector, useAppDispatch } from '../state';
import { useSelector } from 'react-redux';
import { getStaticData } from '../state/slices/staticdata/StaticDataApi';

interface Props {
}


export const AppAuthenticatedInit: FC<Props> = () => {
    const dispatch = useAppDispatch();
    let authState = useSelector(authSelector);
    useEffect(() => {
        if (authState.jwtToken) {
            dispatch(getStaticData({jwtToken: authState.jwtToken!}));
        }
    }, [authState.jwtToken]);
    return (<span/>);
};
