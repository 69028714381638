import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders } from '../slice';
import { Employee } from '../employee';
import { getLastVogActionHistory, VogRequest } from '../vogrequest';
import { Vog } from '../vog';
import { getTasks } from './TaskApi';
import { t } from 'i18next';
import { formatDateForDisplay, formatDateTimeForDisplay } from '../DateUtils';

export type TaskType = 'ALMOST_EXPIRED_VOG' | 'VOG_BEING_REQUESTED' | 'UNMATCHED_VOG_RECEIVED' | 'VOG_REQUEST_MISSING' | 'VOG_REQUEST_WITH_STATUS_NEW';

export interface Task {
    id: string | undefined;
    taskType: TaskType | undefined;
    employee: Employee | undefined;
    vogRequest: VogRequest | undefined;
    vog: Vog | undefined;
}

export interface TaskState extends GenericPageableState<Task> {
}

export const taskSlice = createSlice({
    name: 'task',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getTasks);
    },
});


export function getTaskDescription(task: Task): string {
    switch (task.taskType) {
        case 'ALMOST_EXPIRED_VOG':
            return t('screens.tasks.description.taskType.ALMOST_EXPIRED_VOG', {'vog.validUntil': formatDateForDisplay(task.vog?.validUntil)});
        case 'VOG_BEING_REQUESTED':
            let lastEmailSentAt = formatDateTimeForDisplay(getLastVogActionHistory(task.vogRequest!, 'SHARE_VOG_EMAIL_SEND')?.changedAt)
            return t('screens.tasks.description.taskType.VOG_BEING_REQUESTED', {'vogRequest.statusDate': formatDateForDisplay(task.vogRequest?.statusDate), 'lastEmailSentAt': lastEmailSentAt});
        default:
            return t('screens.tasks.description.taskType.' + task.taskType);
    }
}

