import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateForDisplay, getFullName, getVogsForEmployee, useAppDispatch, Vog, vogSelector, } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { defaultMaterialTablePaginationProps, defaultMaterialTableProps, ErrorAlert, IsTenantAdmin } from '../../molecules';
import { t } from 'i18next';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';


interface Props {
    employeeId: string | undefined;
}


export const EmployeeVogTable: FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const vogs = useSelector(vogSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>(defaultMaterialTablePaginationProps());

    function formatScreeningsProfielen(vog: Vog) {
        const result = [];
        if (vog.specifiekProfielFunctieAspectNr) {
            result.push(<div>{vog.specifiekProfielFunctieAspectNr}. {vog.specifiekFunctieAspectOmschrijving}</div>);
        } else {
            vog.algemeenScreeningsProfielFuncties?.forEach(profile => {
                result.push(<div>{profile.functieAspectNr}. {profile.functieAspectOmschrijving}</div>);
            })
        }
        return (result);
    }

    const columns = useMemo<MRT_ColumnDef<Vog>[]>(
        () => [
            {
                header: t('fields.employee.single.fullName'),
                accessorFn: (originalRow) => getFullName(originalRow.geslachtsnaam, originalRow.voornamen, originalRow.tussenvoegsels),
            },
            {
                header: t('fields.vog.single.afgiftedatum'),
                accessorFn: (originalRow) => formatDateForDisplay(originalRow.afgiftedatum),
            },
            {
                header: t('fields.vog.single.validUntil'),
                accessorFn: (originalRow) => formatDateForDisplay(originalRow.validUntil),
            },
            {
                header: t('fields.vog.single.screeningsProfielen'),
                accessorFn: (originalRow) => formatScreeningsProfielen(originalRow),
            },
        ],
        [],
    );
    useEffect(() => {
        dispatch(getVogsForEmployee({jwtToken: authState.jwtToken, employeeId: props.employeeId, page: pagination.pageIndex, pageSize: pagination.pageSize}));
        // eslint-disable-next-line
    }, [authState.jwtToken, pagination.pageIndex, pagination.pageSize]);

    const table = useMaterialReactTable({
        ...defaultMaterialTableProps(vogs, pagination, setPagination),
        columns,
        muiTableBodyRowProps: ({row}) => ({
            onClick: (event) => {
                navigate('/vog/' + row.original.id);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
    });


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={vogs.error !== undefined}/>
            <MaterialReactTable table={table}/>
            <IsTenantAdmin>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    {/*<PrimaryButton sx={{mt: 2}} onClick={() => {*/}
                    {/*    navigate('/vogrequest/' + props.employeeId + '/add');*/}
                    {/*}}>{t('fields.vogRequest.single.new')}</PrimaryButton>*/}
                </Stack>
            </IsTenantAdmin>
        </Container>
    )


};
