// material-ui
import React from 'react';


interface Props {
    size: string;
    base64EncodedLogo: string;
}

const TenantLogo: React.FC<Props> = (props) => {
    return (
        <img width={props.size} src={props.base64EncodedLogo} alt=""/>
    );
};

export default TenantLogo;
