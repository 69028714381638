import React, { FC, useEffect, useState } from 'react';
import {
    authSelector,
    employeeSelector,
    FunctionProfile,
    functionProfileSelector,
    getEmployee,
    getFunctionProfiles,
    getVogRequest,
    resendShareVogEmail,
    saveVogRequest,
    SCREENINGS_PROFIEL_TYPE_ALGEMEEN,
    SCREENINGS_PROFIEL_TYPE_SPECIFIEK,
    ScreeningsProfiel,
    tenantConfigSelector,
    updateVogRequestStatus,
    useAppDispatch,
    VogRequestForm,
    vogRequestSelector
} from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DateInput, ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { ScreeningsProfielTypeRadioGroup } from '../../atoms/ScreeningsProfielTypeRadioGroup';
import { AlgemeenScreeningsProfielCheckboxGroup, SpecifiekScreeningsProfielRadioGroup } from '../../atoms';
import { VogRequestStatusRequestedConfirmDialog } from './VogRequestStatusRequestedConfirmDialog';
import { FunctionProfileSelectCombo } from '../../molecules/FunctionProfileSelectCombo';
import { enqueueSnackbar } from 'notistack';


export const VogRequestDetailContent: FC = () => {
    const {employeeId, vogRequestId} = useParams();
    const [showVogRequestStatusRequestedConfirmDialog, setShowVogRequestStatusRequestedConfirmDialog] = useState<boolean>(false);
    const [contentDisabled, setContentDisabled] = useState<boolean>(false);
    const [vogRequestForm, setVogRequestForm] = useState<VogRequestForm>(Object.assign(new VogRequestForm(), {
        screeningsProfielType: SCREENINGS_PROFIEL_TYPE_SPECIFIEK,
        employeeId: employeeId,
        status: 'NEW',
        allowedNewStatusses: ['NEW', 'REQUESTED']
    }));
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const authState = useSelector(authSelector);
    const employeeState = useSelector(employeeSelector);
    const vogRequestState = useSelector(vogRequestSelector);
    const tenantConfig = useSelector(tenantConfigSelector);
    const functionProfiles = useSelector(functionProfileSelector);

    useEffect(() => {
        if (authState.jwtToken) {
            dispatch(getFunctionProfiles({jwtToken: authState.jwtToken!, page: 0, pageSize: 100}));
        }
    }, [dispatch, authState.jwtToken]);

    useEffect(() => {
        if (employeeId) {
            dispatch(getEmployee({jwtToken: authState.jwtToken!, employeeId: employeeId}));
        }
    }, [dispatch, employeeId, authState.jwtToken]);

    useEffect(() => {
        if (employeeId) {
            dispatch(getEmployee({jwtToken: authState.jwtToken!, employeeId: employeeId}));
        }
    }, [dispatch, employeeId, authState.jwtToken]);

    useEffect(() => {
        if (vogRequestId) {
            dispatch(getVogRequest({jwtToken: authState.jwtToken!, vogRequestId: vogRequestId}));
        }
    }, [dispatch, vogRequestId, authState.jwtToken]);

    useEffect(() => {
        if (vogRequestId === undefined) {
            handleSetFormValue({
                employeeId: employeeState.singleItem?.id,
                emailAddress: employeeState.singleItem?.emailAddress,
                geslachtsnaam: employeeState.singleItem?.geslachtsnaam,
                tussenvoegsels: employeeState.singleItem?.tussenvoegsels,
                voornamen: employeeState.singleItem?.voornamen,
                geboortedatum: employeeState.singleItem?.geboortedatum,
                geboorteplaats: employeeState.singleItem?.geboorteplaats,
                geboorteland: employeeState.singleItem?.geboorteland
            });
        }
    }, [dispatch, employeeState, authState.jwtToken, vogRequestId]);

    useEffect(() => {
        if (vogRequestId && vogRequestState.singleItem && vogRequestState.singleItem.id) {
            setVogRequestForm(VogRequestForm.from(vogRequestState.singleItem));
        }
    }, [vogRequestState.singleItem, vogRequestId]);

    useEffect(() => {
        if (vogRequestState.loading) {
            setContentDisabled(true);
        } else {
            setContentDisabled(!(vogRequestForm.status === 'NEW'));
        }
    }, [vogRequestState, vogRequestForm]);


    const handleSetFormValue = (param: any) => {
        setVogRequestForm(Object.assign({}, vogRequestForm, param));
    }

    function saveForm(newFormParams: any) {
        const form = Object.assign({}, vogRequestForm, newFormParams)
        console.log('QQQ vogRequestForm: ', form);
        dispatch(saveVogRequest({jwtToken: authState.jwtToken!, vogRequestForm: form}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate(-1)
                    }, 250);
                }
            });
    }

    function isFormValid() {
        return VogRequestForm.isValid(vogRequestForm);
    }


    function onStatusButtonClicked(newStatus: string) {
        if (newStatus === 'REQUESTED') {
            setShowVogRequestStatusRequestedConfirmDialog(true);
        } else {
            handleStatusChange(newStatus);
        }
    }

    function handleStatusChange(newStatus: string) {
        setShowVogRequestStatusRequestedConfirmDialog(false);
        if (vogRequestId && newStatus !== 'REQUESTED') {
            dispatch(updateVogRequestStatus({jwtToken: authState.jwtToken!, vogRequestId: vogRequestId, newStatus: newStatus, vogRequestForm: vogRequestForm}))
                .then((response) => {
                    if (response.type.includes('fulfilled')) {
                        setTimeout(() => { // Use timeout the give time to update the redux store.
                            navigate(-1)
                        }, 250);
                    }
                });
        } else {
            saveForm({status: newStatus});
        }
    }

    function handleSelectFunctionProfile(functionProfile: FunctionProfile) {
        handleSetFormValue({functionProfileId: functionProfile.id,
            screeningsProfielType: functionProfile.screeningsProfiles[0].screeningsProfielType,
            specifiekProfielFunctieAspectNr: functionProfile.screeningsProfiles[0].functieAspectNr,
            specifiekFunctieAspectOmschrijving: functionProfile.screeningsProfiles[0].functieAspectOmschrijving,
            algemeenScreeningsProfielFuncties: functionProfile.screeningsProfiles[0].screeningsProfielType === SCREENINGS_PROFIEL_TYPE_ALGEMEEN ? Object.assign([], functionProfile.screeningsProfiles): []
        });
    }


    function sendShareVogEmail(vogRequestId: string | undefined) {
        if (vogRequestId) {
            dispatch(resendShareVogEmail({jwtToken: authState.jwtToken!, vogRequestId: vogRequestId}))
                .then((response) => {
                    if (response.type.includes('fulfilled')) {
                        enqueueSnackbar(t('screens.tasks.shareVogEmailSent', {email: response.payload.emailAddress}), {variant: 'success'});
                    }
                });
        }

    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={vogRequestState.error !== undefined}/>
            <MainCard title={vogRequestId ? t('fields.vogRequest.single.edit') : t('fields.vogRequest.single.new')}>
                <VogRequestStatusRequestedConfirmDialog
                    showDialog={showVogRequestStatusRequestedConfirmDialog}
                    vogRequest={vogRequestForm}
                    tenantConfig={tenantConfig}
                    onConfirm={() => handleStatusChange('REQUESTED')}
                    onCancel={() => setShowVogRequestStatusRequestedConfirmDialog(false)}
                />
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="emailAddress">{t('fields.vog.single.emailAddress')}</InputLabel>
                                <TextField rows={1} value={vogRequestForm.emailAddress}
                                           disabled={contentDisabled}
                                           onChange={e => handleSetFormValue({emailAddress: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geslachtsnaam">{t('fields.vog.single.geslachtsnaam')}</InputLabel>
                                <TextField rows={1} value={vogRequestForm.geslachtsnaam}
                                           disabled={contentDisabled}
                                           onChange={e => handleSetFormValue({geslachtsnaam: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="tussenvoegsels">{t('fields.vog.single.tussenvoegsels')}</InputLabel>
                                <TextField rows={1} value={vogRequestForm.tussenvoegsels}
                                           disabled={contentDisabled}
                                           onChange={e => handleSetFormValue({tussenvoegsels: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="voornamen">{t('fields.vog.single.voornamen')}</InputLabel>
                                <TextField rows={1} value={vogRequestForm.voornamen}
                                           disabled={contentDisabled}
                                           onChange={e => handleSetFormValue({voornamen: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geboortedatum">{t('fields.vog.single.geboortedatum')}</InputLabel>
                                <DateInput value={vogRequestForm?.geboortedatum} disabled={contentDisabled} onChange={(value) => handleSetFormValue({geboortedatum: value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geboorteplaats">{t('fields.vog.single.geboorteplaats')}</InputLabel>
                                <TextField rows={1} value={vogRequestForm.geboorteplaats}
                                           disabled={contentDisabled}
                                           onChange={e => handleSetFormValue({geboorteplaats: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="geboorteland">{t('fields.vog.single.geboorteland')}</InputLabel>
                                <TextField rows={1} value={vogRequestForm.geboorteland}
                                           disabled={contentDisabled}
                                           onChange={e => handleSetFormValue({geboorteland: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="functieProfiel">{t('fields.vog.single.functieProfiel')}</InputLabel>
                                <FunctionProfileSelectCombo disabled={contentDisabled} selectedFunctionProfileId={'' + vogRequestForm?.functionProfileId} onSelect={handleSelectFunctionProfile} functionProfiles={functionProfiles.list}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="screeningsProfielen">{t('fields.vog.single.screeningsProfielen')}</InputLabel>
                                <ScreeningsProfielTypeRadioGroup disabled={contentDisabled} selectedScreeningsProfielType={vogRequestForm?.screeningsProfielType} onChange={(value) => handleSetFormValue({screeningsProfielType: value})}/>
                            </Stack>
                        </Grid>
                        {(vogRequestForm?.screeningsProfielType === SCREENINGS_PROFIEL_TYPE_SPECIFIEK) &&
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="geboortedatum">{t('fields.vog.single.specifiekeScreeningsProfiel')}</InputLabel>
                                    <SpecifiekScreeningsProfielRadioGroup disabled={contentDisabled}
                                    onChange={(value) => handleSetFormValue({specifiekProfielFunctieAspectNr: value.functieAspectNr, specifiekFunctieAspectOmschrijving: value.functieAspectOmschrijving})}
                                    selectedScreeningProfile={{functieAspectNr: vogRequestForm.specifiekProfielFunctieAspectNr} as ScreeningsProfiel}/>
                                </Stack>
                            </Grid>
                        }
                        {(vogRequestForm?.screeningsProfielType === SCREENINGS_PROFIEL_TYPE_ALGEMEEN) &&
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="geboortedatum">{t('fields.vog.single.algemeenScreeningsProfiel')}</InputLabel>
                                    <AlgemeenScreeningsProfielCheckboxGroup disabled={contentDisabled} selectedScreeningProfiles={vogRequestForm.algemeenScreeningsProfielFuncties}
                                                                            onChange={(value) => handleSetFormValue({algemeenScreeningsProfielFuncties: value})}/>
                                </Stack>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sx={{mt: 4}}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                {(vogRequestForm.status === 'REQUESTED') &&
                                    <Button variant="outlined" color="secondary" onClick={() => sendShareVogEmail(vogRequestId)}>{t('fields.task.taskAction.VOG_BEING_REQUESTED')}</Button>
                                }
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                                {vogRequestForm.allowedNewStatusses?.map((status) => {
                                    if (status === 'NEW') {
                                        return (
                                            <Button key={'button' + status} variant="contained" onClick={saveForm} disabled={vogRequestState.loading || !isFormValid()}>{t('generic.save')}</Button>
                                        )
                                    } else if (['REQUESTED', 'REJECTED', 'CANCELLED'].includes(status)) {
                                        return (
                                            <Button key={'button' + status} variant="contained" onClick={event => onStatusButtonClicked(status)}
                                                    disabled={vogRequestState.loading || !isFormValid()}>{t('fields.vogRequest.statusAction.' + status)}</Button>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};

