import isEmail from "validator/lib/isEmail";
import { VogRequest, VogRequestActionHistory } from './VogRequestSlice';
import { SCREENINGS_PROFIEL_TYPE_ALGEMEEN, SCREENINGS_PROFIEL_TYPE_SPECIFIEK, ScreeningsProfiel } from '../staticdata';
import { formatDateForTransport } from '../DateUtils';

export class VogRequestForm {
    id: string | undefined;
    employeeId: string | undefined = '';
    emailAddress: string | undefined = '';
    geslachtsnaam: string | undefined = '';
    tussenvoegsels: string | undefined = '';
    voornamen: string | undefined = '';
    geboortedatum: Date | undefined;
    geboorteplaats: string | undefined = '';
    geboorteland: string | undefined = '';
    status: string | undefined = 'NEW';
    allowedNewStatusses: string[] | undefined;
    statusDate: Date | undefined;
    functionProfileId: number | undefined;
    screeningsProfielType: string | undefined;
    specifiekProfielFunctieAspectNr: string | undefined;
    specifiekFunctieAspectOmschrijving: string | undefined;
    algemeenScreeningsProfielFuncties: ScreeningsProfiel[] | undefined;
    actionHistory: VogRequestActionHistory[] | undefined;

    static from(vogRequest: VogRequest): VogRequestForm {
        return {
            id: vogRequest.id,
            employeeId: vogRequest.employeeId,
            emailAddress: vogRequest.emailAddress,
            geslachtsnaam: vogRequest.geslachtsnaam,
            tussenvoegsels: vogRequest.tussenvoegsels,
            voornamen: vogRequest.voornamen,
            geboortedatum: vogRequest.geboortedatum,
            geboorteplaats: vogRequest.geboorteplaats,
            geboorteland: vogRequest.geboorteland,
            status: vogRequest.status,
            allowedNewStatusses: vogRequest.allowedNewStatusses,
            statusDate: vogRequest.statusDate,
            functionProfileId: vogRequest.functionProfileId,
            screeningsProfielType: vogRequest.screeningsProfielType,
            specifiekProfielFunctieAspectNr: vogRequest.specifiekProfielFunctieAspectNr,
            specifiekFunctieAspectOmschrijving: vogRequest.specifiekFunctieAspectOmschrijving,
            algemeenScreeningsProfielFuncties: vogRequest.algemeenScreeningsProfielFuncties,
            actionHistory: undefined
        }
    }

    static isValid(form: VogRequestForm | undefined): boolean {
        if (form === undefined) {
            return false;
        }
        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(form.employeeId)) {
            return false;
        }
        if (!containsValue(form.emailAddress)) {
            return false;
        }

        if (!isEmail(form.emailAddress!)) {
            return false;
        }

        if (!containsValue(form.geslachtsnaam)) {
            return false;
        }
        if (!containsValue(form.voornamen)) {
            return false;
        }

        if (!containsValue(form.geboortedatum)) {
            return false;
        }
        if (!containsValue(form.screeningsProfielType)) {
            return false;
        }
        if (!containsValue(form.functionProfileId)) {
            return false;
        }
        if (form.screeningsProfielType !== SCREENINGS_PROFIEL_TYPE_SPECIFIEK && form.screeningsProfielType !== SCREENINGS_PROFIEL_TYPE_ALGEMEEN) {
            return false;
        }
        if (form.screeningsProfielType === SCREENINGS_PROFIEL_TYPE_SPECIFIEK) {
            if (!containsValue(form.specifiekProfielFunctieAspectNr)) {
                return false;
            }
            if (!containsValue(form.specifiekFunctieAspectOmschrijving)) {
                return false;
            }
        }
        if (form.screeningsProfielType === SCREENINGS_PROFIEL_TYPE_ALGEMEEN) {
            if (form.algemeenScreeningsProfielFuncties === undefined) {
                return false;
            }
            if (form.algemeenScreeningsProfielFuncties.length === 0) {
                return false;
            }
        }
        return true;
    }

    static toTransportFormat(vogRequestForm: VogRequestForm) {
        return {
            id: vogRequestForm.id,
            employeeId: vogRequestForm.employeeId,
            emailAddress: vogRequestForm.emailAddress,
            geslachtsnaam: vogRequestForm.geslachtsnaam,
            tussenvoegsels: vogRequestForm.tussenvoegsels,
            voornamen: vogRequestForm.voornamen,
            geboortedatum: formatDateForTransport(vogRequestForm.geboortedatum),
            geboorteplaats: vogRequestForm.geboorteplaats,
            geboorteland: vogRequestForm.geboorteland,
            functionProfileId: vogRequestForm.functionProfileId,
            screeningsProfielType: vogRequestForm.screeningsProfielType,
            specifiekProfielFunctieAspectNr: vogRequestForm.specifiekProfielFunctieAspectNr,
            specifiekFunctieAspectOmschrijving: vogRequestForm.specifiekFunctieAspectOmschrijving,
            algemeenScreeningsProfielFuncties: vogRequestForm.algemeenScreeningsProfielFuncties,
            status: vogRequestForm.status
        }
    }
}


