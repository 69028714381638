// third-party
import {FormattedMessage} from 'react-intl';

// assets
import { Danger, Flash, HomeTrendUp, People, Profile, Profile2User, Star1 } from 'iconsax-react';
import React from 'react';
import {NavItemType} from '../../../atoms/types/menu';

// type

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const manage: NavItemType = {
  id: 'group-manage',
  title: <FormattedMessage id="menu.manage" />,
  type: 'group',
  children: [
    {
      id: 'employee',
      title: <FormattedMessage id="menu.employee" />,
      type: 'item',
      url: '/employees',
      icon: Profile2User,
      breadcrumbs: true
    },
    {
      id: 'functionProfile',
      title: <FormattedMessage id="menu.functionProfile" />,
      url: '/functionprofiles',
      type: 'item',
      icon: Profile,
      breadcrumbs: true
    }
  ]
};

export default manage;
