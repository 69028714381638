import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { getStaticData } from './StaticDataApi';


export interface StaticData {
    tenantConfig: TenantConfig;
    screeningsProfielen: ScreeningsProfiel[];
}

export interface TenantConfig {
    name: string;
    base64EncodedLogo?: string;
    primaryColors?: string[];
}
export interface ScreeningsProfiel {
    functieAspectNr: string;
    functieAspectOmschrijving: string;
    screeningsProfielType: string;
}


export interface StaticDataState extends GenericState<StaticData> {
}

export const staticDataSlice = createSlice({
    name: 'tenantConfig',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getStaticData);
    },
});

export const SCREENINGS_PROFIEL_TYPE_SPECIFIEK: string ='SPECIFIEK'
export const SCREENINGS_PROFIEL_TYPE_ALGEMEEN: string ='ALGEMEEN'
