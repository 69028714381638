import { GenericPageableState } from '../../state';
import { PaginationState } from '@tanstack/react-table';
import { MRT_TableOptions } from 'material-react-table';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';


export const defaultMaterialTablePaginationProps = () => {
    return {
        pageIndex: 0,
        pageSize: 10,
    }
}
export const defaultMaterialTableProps = (state: GenericPageableState<any>, pagination: PaginationState, setPagination: (value: (((prevState: PaginationState) => PaginationState) | PaginationState)) => void) => {
    return {
        data: state.list,
        rowCount: state.totalElements,
        pageCount: state.totalPages,
        onPaginationChange: setPagination,
        state: {pagination},
        manualPagination: true,
        enableRowSelection: false,
        enableColumnOrdering: false,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableTopToolbar: false,
        localization: MRT_Localization_NL,
        mrtTheme: {
            baseBackgroundColor: 'rgba(0, 0, 0, 0)'

        },
        muiTablePaperProps: {
            elevation: 0
        },
        muiTableBodyCellProps: {
            align: 'left',
            sx: {verticalAlign: 'top'}
        },
    } as MRT_TableOptions<any>
}
