import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateForDisplay, getFullNameFromEmployee, getTaskDescription, getVog, ScreeningsProfiel, Task, taskSelector, updateVogRequestStatus, useAppDispatch, vogSelector, } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton } from '../../atoms';

interface Props {
}


export const TaskJudgeVogDifferencesContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const tasks = useSelector(taskSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const {vogId} = useParams();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });

    const columns = useMemo<MRT_ColumnDef<Task>[]>(
        () => [
            {
                header: t('fields.employee.single.fullName'),
                accessorFn: (originalRow) => getFullNameFromEmployee(originalRow.employee),
                size: 200
            },
            {
                header: t('fields.employee.single.geboortedatum'),
                accessorFn: (originalRow) => formatDateForDisplay(originalRow.employee?.geboortedatum),
                size: 50
            },
            {
                header: t('screens.tasks.description.title'),
                accessorFn: (originalRow) => getTaskDescription(originalRow),
                size: 400,
            }
        ],
        [],
    );
    useEffect(() => {
        dispatch(getVog({jwtToken: authState.jwtToken, vogId: vogId}));
        // eslint-disable-next-line
    }, [authState.jwtToken, pagination.pageIndex, pagination.pageSize]);

    let vog = useSelector(vogSelector).singleItem;

    function getRow(label: string, column1: any | undefined, column2: any | undefined) {
        const value1 = (column1) ? column1 : '';
        const value2 = (column2) ? column2 : '';
        const differs = !(value1 === value2);
        return (
            <TableRow key={label} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row">{label}</TableCell>
                <TableCell><Box display="flex" alignItems="center">{differs && (<CloseIcon sx={{color: '#ff0000'}}/>)}<span>{column1}</span></Box></TableCell>
                <TableCell><Box display="flex" alignItems="center">{differs && (<CloseIcon sx={{color: '#ff0000'}}/>)}<span>{column2}</span></Box></TableCell>
            </TableRow>
        )

    }

    function getSpecifiekScreeningsProfielText(specifiekeScreeningsProfiel: string | undefined, specifiekFunctieAspectOmschrijving: string | undefined) {
        return (specifiekeScreeningsProfiel ? specifiekeScreeningsProfiel + ': ' : '') + (specifiekFunctieAspectOmschrijving ? specifiekFunctieAspectOmschrijving : '');
    }

    function getAlgemeenScreeningsProfiel(algemeenScreeningsProfielFuncties: ScreeningsProfiel[] | undefined) {
        if (algemeenScreeningsProfielFuncties) {
            let result: any[];
            result = [];
            algemeenScreeningsProfielFuncties?.forEach(profile => {
                result.push(<div key={profile.functieAspectNr}>{profile.functieAspectNr}. {profile.functieAspectOmschrijving}</div>);
            });
            return (result);
        }
        return '';
    }

    function sendStatusUpdate(newStatus: string) {
        dispatch(updateVogRequestStatus({jwtToken: authState.jwtToken, vogRequestId: vog?.vogRequest?.id, newStatus: newStatus}))
        .then(response => {
            if (response.type.includes('fulfilled')) {
                setTimeout(() => {
                    // Use timeout the give time to update the redux store.
                    navigate(-1);
                }, 250);
            }
        });
    }
    function acceptDifferences() {
        sendStatusUpdate('ACCEPTED');
    }
    function rejectDifferences() {
        sendStatusUpdate('REJECTED');
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={tasks.error !== undefined}/>
            <h2>{t('screens.judgeVogDifferences.title')}</h2>
            <p>{t('screens.judgeVogDifferences.body')}</p>
            {vog && vog.vogRequest && (
                <TableContainer component={Paper} sx={{marginTop: 4}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('screens.judgeVogDifferences.vogRequestColumnTitle')}</TableCell>
                                <TableCell>{t('screens.judgeVogDifferences.vogColumnTitle')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getRow(t('fields.vog.single.geslachtsnaam'), vog.vogRequest.geslachtsnaam, vog.geslachtsnaam)}
                            {getRow(t('fields.vog.single.tussenvoegsels'), vog.vogRequest.tussenvoegsels, vog.tussenvoegsels)}
                            {getRow(t('fields.vog.single.voornamen'), vog.vogRequest.voornamen, vog.voornamen)}
                            {getRow(t('fields.vog.single.geboortedatum'), formatDateForDisplay(vog.vogRequest.geboortedatum), formatDateForDisplay(vog.geboortedatum))}
                            {/*{getRow(t('fields.vog.single.geboorteplaats'), vog.vogRequest.geboorteplaats, vog.geboorteplaats)}*/}
                            {getRow(t('fields.vog.single.specifiekeScreeningsProfiel'), getSpecifiekScreeningsProfielText(vog.vogRequest.specifiekProfielFunctieAspectNr, vog.vogRequest.specifiekFunctieAspectOmschrijving), getSpecifiekScreeningsProfielText(vog.specifiekProfielFunctieAspectNr, vog.specifiekFunctieAspectOmschrijving))}
                            {getRow(t('fields.vog.single.algemeenScreeningsProfiel'), getAlgemeenScreeningsProfiel(vog.vogRequest.algemeenScreeningsProfielFuncties), getAlgemeenScreeningsProfiel(vog.algemeenScreeningsProfielFuncties))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <PrimaryButton sx={{mt: 2, mr:1}} onClick={() => acceptDifferences()}>{t('screens.judgeVogDifferences.buttonAccept')}</PrimaryButton>
            <PrimaryButton sx={{mt: 2}} onClick={() => rejectDifferences()}>{t('screens.judgeVogDifferences.buttonReject')}</PrimaryButton>
        </Container>
    )
};
