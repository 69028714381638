import React, { FC, useEffect, useState } from 'react';
import {
    authSelector,
    functionProfileSelector,
    FunctionProfileForm,
    getFunctionProfile,
    saveFunctionProfile,
    useAppDispatch, SCREENINGS_PROFIEL_TYPE_SPECIFIEK, ScreeningsProfiel, SCREENINGS_PROFIEL_TYPE_ALGEMEEN
} from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { AlgemeenScreeningsProfielCheckboxGroup, ScreeningsProfielTypeRadioGroup, SpecifiekScreeningsProfielRadioGroup } from '../../atoms';


export const FunctionProfileEditDetailContent: FC = () => {
    const [functionProfileForm, setFunctionProfileForm] = useState<FunctionProfileForm>(new FunctionProfileForm());
    const dispatch = useAppDispatch();
    const {functionProfileId} = useParams();
    const functionProfileState = useSelector(functionProfileSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);
    const [screeningsProfielType, setScreeningsProfielType] = useState<string>(SCREENINGS_PROFIEL_TYPE_SPECIFIEK);

    useEffect(() => {
        if (functionProfileId) {
            dispatch(getFunctionProfile({jwtToken: authState.jwtToken!, functionProfileId: functionProfileId}));
        }
    }, [dispatch, functionProfileId, authState.jwtToken]);

    useEffect(() => {
        if (functionProfileId && functionProfileState.singleItem && functionProfileState.singleItem.id) {
            setFunctionProfileForm(FunctionProfileForm.from(functionProfileState.singleItem));
            if (functionProfileState.singleItem.screeningsProfiles && functionProfileState.singleItem.screeningsProfiles.length > 0) {
                setScreeningsProfielType(functionProfileState.singleItem.screeningsProfiles[0].screeningsProfielType);
            } else {
                setScreeningsProfielType(SCREENINGS_PROFIEL_TYPE_SPECIFIEK);
            }
        }
    }, [functionProfileState.singleItem, functionProfileId]);

    const handleSetFormValue = (param: any) => {
        setFunctionProfileForm(Object.assign({}, functionProfileForm, param));
    }

    const changeScreeningsprofileType = (value: string) => {
        setScreeningsProfielType(value);
        handleSetFormValue({screeningsProfiles: []});
    }


    function saveForm() {
        dispatch(saveFunctionProfile({jwtToken: authState.jwtToken!, functionProfileForm: functionProfileForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate(-1)
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return FunctionProfileForm.isValid(functionProfileForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={functionProfileState.error !== undefined}/>
            <h2>{functionProfileId ? t('fields.functionProfile.single.edit') : t('fields.functionProfile.single.new')}</h2>
            <MainCard>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="name">{t('fields.functionProfile.single.name')}</InputLabel>
                                <TextField rows={1} value={functionProfileForm.name}
                                           onChange={e => handleSetFormValue({name: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="screeningsProfielen">{t('fields.vog.single.screeningsProfielen')}</InputLabel>
                                <ScreeningsProfielTypeRadioGroup selectedScreeningsProfielType={screeningsProfielType} onChange={changeScreeningsprofileType}/>
                            </Stack>
                        </Grid>
                        {(screeningsProfielType === SCREENINGS_PROFIEL_TYPE_SPECIFIEK) &&
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="geboortedatum">{t('fields.vog.single.specifiekeScreeningsProfiel')}</InputLabel>
                                    <SpecifiekScreeningsProfielRadioGroup onChange={(value) => handleSetFormValue({screeningsProfiles: [value]})}
                                                                          selectedScreeningProfile={functionProfileForm.screeningsProfiles && functionProfileForm.screeningsProfiles?.length > 0 ? functionProfileForm.screeningsProfiles[0] : undefined}/>
                                </Stack>
                            </Grid>
                        }
                        {(screeningsProfielType === SCREENINGS_PROFIEL_TYPE_ALGEMEEN) &&
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="geboortedatum">{t('fields.vog.single.algemeenScreeningsProfiel')}</InputLabel>
                                    <AlgemeenScreeningsProfielCheckboxGroup selectedScreeningProfiles={functionProfileForm.screeningsProfiles}
                                                                            onChange={(value) => handleSetFormValue({screeningsProfiles: value})}/>
                                </Stack>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sx={{mt: 4}}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm} disabled={functionProfileState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};

