export interface AppTexts {
    appName: {
        displayName: string;
        name: string;
    };
    busyInitializing: string;
    menu: {
        home: string;
        vog: string;
        employee: string;
        manage: string;
        functionProfile: string;
    };
    generic: {
        yes: string;
        no: string;
        ok: string;
        cancel: string;
        add: string;
        save: string;
        delete: string;
        edit: string;
        back: string;
        loading: string;
        downloadAsExcel: string;
        form: {
            add: string;
            edit: string;
        }
        imageinput: {
            crop: string;
            dragText: string;
        }
        time: string;
    };
    error: {
        retrievingData: string;
        errorCodes: {
            "ERR-1": string;
            "ERR-2": string;
            "ERR-4": string;
            "ERR-6": string;
        };
        invalidEmail: string;
    };
    dashboard: {
        title: string;
    }
    fields: {
        signup: {
            single: {
                typeDescription: string;
                title: string;
                companyName: string;
                contactPersonName: string;
                contactPersonEmailAddress: string;
                subscriptionType: string;
            }
        }
        vog: {
            single: {
                typeDescription: string;
                emailAddress: string;
                afgiftedatum: string;
                validFrom: string;
                validUntil: string;
                kenmerken: string;
                naam: string;
                geslachtsnaam: string;
                tussenvoegsels: string;
                voornamen: string;
                geboortedatum: string;
                geboorteplaats: string;
                geboorteland: string;
                doelWaarde: string;
                doelOmschrijving: string;
                doelFunctie: string;
                belanghebbendeKvk: string;
                belanghebbendeNaam: string;
                functieProfiel: string;
                screeningsProfielen: string;
                specifiekeScreeningsProfiel: string;
                algemeenScreeningsProfiel: string;
                receivedAt: string;
            }
            plural: {
                typeDescription: string;
            }
        }
        vogScreeningsProfielFunctie: {
            single: {
                typeDescription: string;
                functieAspectNr: string;
                functieAspectOmschrijving: string;
            }
            plural: {
                typeDescription: string;
            }
        }
        vogScreeningsProfielType: {
            specifiek: string;
            algemeen: string;
        }
        vogRequest: {
            single: {
                typeDescription: string;
                new: string;
                edit: string;
                status: string;
                statusDate: string;
            }
            plural: {
                typeDescription: string;
            },
            status: {
                NEW: string;
                REQUESTED: string;
                VOG_RECEIVED: string;
                REJECTED: string;
                CANCELLED: string;
                VOG_RECEIVED_NO_FULL_MATCH: string;
            },
            statusAction: {
                REQUESTED: string;
                REJECTED: string;
                CANCELLED: string;
            }

        }
        employee: {
            single: {
                typeDescription: string;
                emailAddress: string;
                geslachtsnaam: string;
                tussenvoegsels: string;
                voornamen: string;
                geboortedatum: string;
                geboorteplaats: string;
                geboorteland: string;
                notes: string;
                new: string;
                edit: string;
                fullName: string;
            }
            plural: {
                typeDescription: string;
            }
        },
        functionProfile: {
            single: {
                name: string;
                new: string;
                edit: string;
            }
            plural: {
                typeDescription: string;
            }
        },
        task: {
            single: {
                typeDescription: string;
            },
            plural: {
                typeDescription: string;
            },
            taskType: {
                ALMOST_EXPIRED_VOG: string;
                VOG_BEING_REQUESTED: string;
                UNMATCHED_VOG_RECEIVED: string;
                VOG_REQUEST_MISSING: string;
                VOG_REQUEST_WITH_STATUS_NEW: string;
            },
            taskAction: {
                ALMOST_EXPIRED_VOG: string;
                VOG_BEING_REQUESTED: string;
                UNMATCHED_VOG_RECEIVED: string;
                VOG_REQUEST_MISSING: string;
                VOG_REQUEST_WITH_STATUS_NEW: string;
            }
        }
    };
    screens: {
        vogRequestStatusRequestedConfirmDialog: {
            title: string;
            body: string;
        },
        tasks: {
            title: string;
            description: {
                title: string;
                taskType: {
                    ALMOST_EXPIRED_VOG: string;
                    VOG_BEING_REQUESTED: string;
                    UNMATCHED_VOG_RECEIVED: string;
                    VOG_REQUEST_MISSING: string;
                    VOG_REQUEST_WITH_STATUS_NEW: string;
                }
            },
            shareVogEmailSent: string;
        },
        judgeVogDifferences: {
            title: string;
            body: string;
            vogRequestColumnTitle: string;
            vogColumnTitle: string;
            buttonAccept: string;
            buttonReject: string;
        },
        employee: {
            deleteConfirmDialogTitle: string;
            deleteConfirmDialogBody: string;
        }
    }

}

const getAppTexts = (): AppTexts => {
    return {
        appName: {
            name: 'ServiceMelder',
            displayName: 'Service Melder',
        },
        busyInitializing: 'Bezig met initialiseren',
        menu: {
            home: 'Home',
            vog: 'Geregistreerde SmartVOGs',
            employee: 'Vrijwilligers',
            tasks: 'Notificaties',
            vogs: 'Verklaringen omtrent Gedrag',
            manage: 'Beheer',
            functionProfile: 'Functie profielen'
        },
        generic: {
            yes: 'Ja',
            no: 'Nee',
            ok: 'OK',
            cancel: 'Annuleren',
            add: 'Toevoegen',
            save: 'Opslaan',
            edit: 'Wijzigen',
            delete: 'Verwijderen',
            back: 'Terug',
            downloadAsExcel: 'Download Excel',
            loading: 'Laden...',
            form: {
                add: "Toevoegen",
                edit: "Wijzig"
            },
            imageinput: {
                crop: 'Bijsnijden',
                dragText: 'Sleep foto naar dit vak of klik hier om een foto te selecteren'
            },
            time: 'Tijd'
        },
        error: {
            retrievingData: "Er is is mis gegaan bij het ophalen van de data. Probeer het later nog een keer.",
            errorCodes: {
                "ERR-1": 'E-mail adres is niet toegestaan. Gebruik uw zakelijke e-mail adres.',
                "ERR-2": 'Uw validatiecode is niet (meer) geldig.',
                "ERR-4": 'E-mail adres is niet toegestaan. Gebruik uw zakelijke e-mail adres.',
                "ERR-6": 'Uw heeft geen toegang tot dit systeem.'
            },
            invalidEmail: "Ongeldig e-mailadres."
        },
        dashboard: {
            title: 'Dashboard'
        },
        fields: {
            signup: {
                single: {
                    typeDescription: 'Registreren',
                    title: 'Registreren',
                    companyName: 'Naam bedrijf',
                    contactPersonName: 'Naam contactpersoon',
                    contactPersonEmailAddress: 'Emailadres contactpersoon',
                    subscriptionType: "Abonnementstype"
                },
            },
            vog: {
                single: {
                    typeDescription: 'Verklaring Omtrent het Gedrag',
                    emailAddress: 'Emailadres',
                    afgiftedatum: 'Afgiftedatum',
                    validFrom: 'Geldig vanaf',
                    validUntil: 'Geldig tot',
                    kenmerken: 'Kenmerken',
                    naam: 'Naam',
                    geslachtsnaam: 'Geslachtsnaam',
                    tussenvoegsels: 'Tussenvoegsels',
                    voornamen: 'Voornamen',
                    geboortedatum: 'Geboortedatum',
                    geboorteplaats: 'Geboorteplaats',
                    geboorteland: 'Geboorteland',
                    doelWaarde: 'Doel ',
                    doelOmschrijving: 'Doelomschrijving',
                    doelFunctie: 'Functie',
                    belanghebbendeKvk: 'KvK belanghebbende',
                    belanghebbendeNaam: 'Naam belanghebbende',
                    functieProfiel: 'Functie profiel',
                    screeningsProfielen: 'Screeningsprofielen',
                    specifiekeScreeningsProfiel: 'Specifiek screeningsprofiel',
                    algemeenScreeningsProfiel: 'Algemeen screeningsprofiel',
                    receivedAt: 'Ontvangen op',
                },
                plural: {
                    typeDescription: 'Geregistreerde SmartVOGs'
                }
            },
            vogScreeningsProfielFunctie: {
                single: {
                    typeDescription: 'Screeningsprofiel',
                    functieAspectNr: 'Functie aspect nummer',
                    functieAspectOmschrijving: 'Functie aspect omschrijving'
                },
                plural: {
                    typeDescription: 'Screeningsprofielen'
                }
            },
            vogScreeningsProfielType: {
                specifiek: 'Specifiek',
                algemeen: 'Algemeen'
            },
            vogRequest: {
                single: {
                    typeDescription: 'SmartVOG verzoek',
                    new: "Verzoek om SmartVOG",
                    edit: "Wijzig SmartVOG verzoek",
                    status: 'Status',
                    statusDate: 'Status datum'
                },
                plural: {
                    typeDescription: "SmartVOG aanvragen"
                },
                status: {
                    ALL: 'Alles',
                    NEW: 'Nieuw',
                    REQUESTED: 'Aan te leveren',
                    VOG_RECEIVED: 'Ontvangen',
                    ACCEPTED: 'Geaccepteerd',
                    REJECTED: 'Afgekeurd',
                    CANCELLED: 'Afgebroken',
                    VOG_RECEIVED_NO_FULL_MATCH: 'Te beoordelen',
                    EXPIRED: 'Verlopen'
                },
                statusAction: {
                    REQUESTED: 'Verzoeken',
                    VOG_RECEIVED: 'VOG',
                    REJECTED: 'Afkeuren',
                    CANCELLED: 'Afbreken'
                }
            },
            employee: {
                single: {
                    typeDescription: 'Vrijwilliger',
                    emailAddress: 'Emailadres',
                    geslachtsnaam: 'Geslachtsnaam',
                    tussenvoegsels: 'Tussenvoegsels',
                    voornamen: 'Voornamen',
                    geboortedatum: 'Geboortedatum',
                    geboorteplaats: 'Geboorteplaats',
                    geboorteland: 'Geboorteland',
                    notes: 'Notities',
                    edit: 'Wijzig vrijwilliger',
                    new: 'Nieuwe vrijwilliger',
                    fullName: 'Naam'
                },
                plural: {
                    typeDescription: 'Vrijwilligers'
                }
            },
            functionProfile: {
                single: {
                    name: 'Naam',
                    new: 'Nieuw functie profiel',
                    edit: 'Wijzig functie profiel'
                },
                plural: {
                    typeDescription: 'Functie profielen',
                }
            },
            task: {
                single: {
                    typeDescription: 'Taak'
                },
                plural: {
                    typeDescription: 'Notificaties'
                },
                taskType: {
                    ALMOST_EXPIRED_VOG: 'Vog is bijna verlopen',
                    VOG_BEING_REQUESTED: 'Vog is aangevraagd maar nog niet ontvangen',
                    UNMATCHED_VOG_RECEIVED: 'Vog is ontvangen maar wijkt af van de aanvraag',
                    VOG_REQUEST_MISSING: 'Nog geen vog voor aangevraagd',
                    VOG_REQUEST_WITH_STATUS_NEW: 'Vog aanvraag is nog niet ingediend'
                },
                taskAction: {
                    ALMOST_EXPIRED_VOG: 'Vraag nieuwe SmartVOG aan',
                    VOG_BEING_REQUESTED: 'Verstuur herinnering',
                    UNMATCHED_VOG_RECEIVED: 'Bekijk verschillen',
                    VOG_REQUEST_MISSING: 'Vraag SmartVOG aan',
                    VOG_REQUEST_WITH_STATUS_NEW: 'Verzoek om SmartVOG'
                }

            }

        },
        screens: {
            vogRequestStatusRequestedConfirmDialog: {
                title: 'Versturen SmartVOG verzoek',
                body: 'U heeft het verzoek voor een SmartVOG geregistreerd in de SmartVOG administratie.<br/>' +
                    'Het proces ziet er verder als volgt uit:' +
                    '<ol>' +
                    '<li style="margin: 10px 0">De SmartVOG wordt aangemaakt</li>' +
                    '<li style="margin: 10px 0">{{fullName}} ontvangt een email met het verzoek om de SmartVOG app te installeren, met daarin tevens een validatie QR-code waarmee de gedownloade SmartVOG kan worden gedeeld met de vereniging.</li>' +
                    '<li style="margin: 10px 0">{{fullName}} ontvangt tevens een email met een QR-code voor het ophalen van de SmartVOG. Hiermee kan {{firstName}} via de SmartVOG app de SmartVOG ophalen om deze vervolgens te delen met de vereniging.</li>' +
                    '</ol>' +
                    '<p>Wilt u het verzoek voor de SmartVOG versturen?</p>'
            },
            tasks: {
                title: 'Taken',
                description: {
                    title: 'Toelichting',
                    taskType: {
                        ALMOST_EXPIRED_VOG: 'VOG verloopt op {{vog.validUntil}}.',
                        VOG_BEING_REQUESTED: `SmartVOG is op {{vogRequest.statusDate}} aangevraagd. Wellicht heeft de vrijwilliger de SmartVOG inmiddels ontvangen maar nog niet gedeeld. Een mail met het verzoek de SmartVOG te delen is verstuurd op {{lastEmailSentAt}}`,
                        UNMATCHED_VOG_RECEIVED: 'Er is een VOG aangeleverd. Sommige kenmerken van de aangeleverde VOG verschillen met de gewenste VOG.',
                        VOG_REQUEST_MISSING: 'Er is nog geen VOG aangevraagd.',
                        VOG_REQUEST_WITH_STATUS_NEW: 'Het verzoek voor de SmartVOG is nog niet verstuurd. Verstuur nu een mail met het verzoek de SmartVOG te delen.'
                    }
                },
                shareVogEmailSent: 'Verzoek tot delen van de SmartVOG is verzonden naar {{email}}'
            },
            judgeVogDifferences: {
                title: 'Beoordelen verschillen',
                body: 'De gegevens van de aangevraagde VOG en de ontvangen VOG komen niet helemaal overeen. Controleer of de ontvangen VOG voldoet.',
                vogRequestColumnTitle: 'Aangevraagd',
                vogColumnTitle: 'Ontvangen',
                buttonAccept: 'Accepteer verschillen',
                buttonReject: 'Weiger VOG'
            },
            employee: {
                deleteConfirmDialogBody: 'Weet u zeker dat u deze vrijwilliger wilt verwijderen. Alle gegevens van de vrijwilliger worden definitief verwijderd.',
                deleteConfirmDialogTitle: 'Bevestig'
            }
        }
    } as AppTexts;
};

const flatten: (object: any, prefix?: string) => any = (object, prefix = '') =>
    Object.keys(object).reduce(
        (prev, element) =>
            object[element] &&
            typeof object[element] === 'object' &&
            !Array.isArray(object[element])
                ? {...prev, ...flatten(object[element], `${prefix}${element}.`)}
                : {...prev, ...{[`${prefix}${element}`]: object[element]}},
        {},
    );

export const getTranslations = (): {} => {
    return flatten(getAppTexts(), '');
}
