import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders } from '../slice';
import { getVog, getVogs, getVogsForEmployee } from './VogApi';
import { ScreeningsProfiel } from '../staticdata';
import { VogRequest } from '../vogrequest';


export interface Vog {
    id: string | undefined;
    emailAddress: string | undefined;
    afgiftedatum: Date | undefined;
    validFrom: Date | undefined;
    validUntil: Date | undefined;
    kenmerken: string | undefined;
    geslachtsnaam: string | undefined;
    tussenvoegsels: string | undefined;
    voornamen: string | undefined;
    geboortedatum: Date | undefined;
    geboorteplaats: string | undefined;
    geboorteland: string | undefined;
    doelWaarde: string | undefined;
    doelOmschrijving: string | undefined;
    doelFunctie: string | undefined;
    belanghebbendeKvk: string | undefined;
    belanghebbendeNaam: string | undefined;
    specifiekProfielFunctieAspectNr: string | undefined;
    specifiekFunctieAspectOmschrijving: string | undefined;
    algemeenScreeningsProfielFuncties: ScreeningsProfiel[] | undefined;
    receivedAt: Date | undefined;
    vogRequest: VogRequest | undefined;
}

export interface VogState extends GenericPageableState<Vog> {
}

export const vogSlice = createSlice({
    name: 'vog',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getVogs);
        addGenericPageableStateListBuilders(builder, getVogsForEmployee);
        addGenericPageableStateSingleBuilders(builder, getVog);
    },
});

