import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericPagableState, GenericPageableState } from '../model';
import {
    addGenericPageableStateListBuilders, addGenericPageableStateSingleBuilders
} from '../slice';
import { ScreeningsProfiel } from '../staticdata';
import { getFunctionProfile, getFunctionProfiles, saveFunctionProfile } from './FunctionProfileApi';


export interface FunctionProfile {
    id: number;
    name: string;
    screeningsProfiles: ScreeningsProfiel[];
}

export interface FunctionProfileState extends GenericPageableState<FunctionProfile> {
}

export const functionProfileSlice = createSlice({
    name: 'functionProfile',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getFunctionProfiles);
        addGenericPageableStateSingleBuilders(builder, getFunctionProfile);
        addGenericPageableStateSingleBuilders(builder, saveFunctionProfile);
    },
});
