import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../state';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PopupTransition } from './Transitions';
import { t } from 'i18next';
import { PrimaryButton } from './PrimaryButton';


interface Props {
    buttonLabel: string;
    onConfirm: () => void;
    onCancel?: () => void;
    title: string;
    bodyText: string;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}


export const ButtonConfirm: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = useState(false)
    const authState = useSelector(authSelector);

    function onConfirm() {
        setShowDialog(false);
        props.onConfirm();
    }

    function onCancel() {
        setShowDialog(false);
        if (props.onCancel) {
            props.onConfirm();
        }
    }

    return (
        <span>
          <Button variant={props.variant} color={props.color} onClick={() => setShowDialog(true)}>{props.buttonLabel}</Button>
          <Dialog
              maxWidth="sm"
              TransitionComponent={PopupTransition}
              keepMounted
              fullWidth
              open={showDialog}
              sx={{'& .MuiDialog-paper': {p: 2}, transition: 'transform 225ms'}}
          >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
              {props.bodyText}
            </DialogContent>
            <DialogActions className="d-flex justify-content-between">
              <PrimaryButton onClick={onConfirm}>{t('generic.ok')}</PrimaryButton>
              <PrimaryButton onClick={onCancel}>{t('generic.cancel')}</PrimaryButton>
            </DialogActions>
          </Dialog>
        </span>
    )
};


PrimaryButton.defaultProps = {
    onlyVisibleForTenantAdmins: false
}
