import React, { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import AuthWrapper from '../../molecules/auth/AuthWrapper';
import AuthPrepare from './AuthPrepare';

interface Props {
}


export const LoginPrepareContent: FC<Props> = () => {

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                           sx={{mb: {xs: -0.5, sm: 0.5}}}>
                        <Typography variant="h3">Login</Typography>
                        {/*<Typography*/}
                        {/*    component={Link}*/}
                        {/*    to={'/register'}*/}
                        {/*    variant="body1"*/}
                        {/*    sx={{textDecoration: 'none'}}*/}
                        {/*    color="primary"*/}
                        {/*>*/}
                        {/*    Nog geen account?*/}
                        {/*</Typography>*/}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AuthPrepare/>
                </Grid>
            </Grid>
        </AuthWrapper>

    )

};
