import React, { FC, useEffect, useMemo, useState } from 'react';
import { SCREENINGS_PROFIEL_TYPE_SPECIFIEK, ScreeningsProfiel, screeningsProfielenSelector } from '../../state';
import { FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material';
import { useSelector } from 'react-redux';


interface Props {
    selectedScreeningProfile: ScreeningsProfiel | undefined;
    onChange: (screeningsProfiel: ScreeningsProfiel) => void;
    disabled?: boolean;
}


export const SpecifiekScreeningsProfielRadioGroup: FC<Props> = (props) => {
    const [screeningsProfile, setScreeningsProfile] = useState<string>('');

    let allScreeningsProfiles = useSelector(screeningsProfielenSelector);
    const screeningsProfiles = useMemo(() => {
        if (allScreeningsProfiles) {
            return allScreeningsProfiles.filter(p => p.screeningsProfielType === SCREENINGS_PROFIEL_TYPE_SPECIFIEK)
                .sort((a, b) => {
                    if (b > a) return 1;
                    if (b < a) return -1;
                    return 0;
                })

        }
    }, [allScreeningsProfiles]);

    useEffect(() => {
        if (props.selectedScreeningProfile && props.selectedScreeningProfile.functieAspectNr) {
            setScreeningsProfile(props.selectedScreeningProfile.functieAspectNr);
        }
    }, [props.selectedScreeningProfile]);

    const onSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = ((event.target as HTMLInputElement).value);
        setScreeningsProfile(selectedValue);
        const functieProfiel = screeningsProfiles?.filter(p => p.functieAspectNr === selectedValue)[0];
        props.onChange(functieProfiel!);
    };

    return (
        <Grid container spacing={3}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group-screenings-profiel"
                value={screeningsProfile}
                onChange={onSelectItem}
                row>
                {screeningsProfiles?.map((screeningsProfile) => (
                    <Grid item xs={12} md={6} key={screeningsProfile.functieAspectNr}>
                        <FormControlLabel disabled={props.disabled} value={screeningsProfile.functieAspectNr} control={<Radio/>} label={
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <Grid item sx={{mr: 1}}>{screeningsProfile.functieAspectNr}</Grid>
                                <Grid item>{screeningsProfile.functieAspectOmschrijving}</Grid>
                            </Stack>
                        }/>
                    </Grid>
                ))}
            </RadioGroup>
        </Grid>

    )
};




