import React, { FC, useMemo } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PopupTransition } from '../../atoms/Transitions';
import { PrimaryButton } from '../../atoms';
import { t } from 'i18next';
import { getFullNameInReadableFormatFromVogRequest, TenantConfig, VogRequest, VogRequestForm } from '../../../state';
import isEmail from 'validator/lib/isEmail';
import DOMPurify from 'dompurify'

export interface Props {
    showDialog: boolean;
    vogRequest: VogRequest | VogRequestForm;
    tenantConfig: TenantConfig | undefined;
    onConfirm: () => void;
    onCancel: () => void;
}


export const VogRequestStatusRequestedConfirmDialog: FC<Props> = (props) => {

    const textVariables = useMemo(() => {
        if (props.vogRequest.emailAddress && isEmail(props.vogRequest.emailAddress)) {
            return {
                emailAddress: props.vogRequest.emailAddress,
                tenantName: props.tenantConfig?.name,
                fullName: getFullNameInReadableFormatFromVogRequest(props.vogRequest),
                firstName: props.vogRequest.voornamen
            };
        }
    }, [props.vogRequest, props.tenantConfig?.name]);

    return (
        <Dialog
            maxWidth="sm"
            TransitionComponent={PopupTransition}
            keepMounted
            fullWidth
            open={props.showDialog}
            sx={{ '& .MuiDialog-paper': { p: 2 }, transition: 'transform 225ms' }}
        >
            <DialogTitle>{t('screens.vogRequestStatusRequestedConfirmDialog.title')}</DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t('screens.vogRequestStatusRequestedConfirmDialog.body', textVariables))}}/>
            </DialogContent>
            <DialogActions className="d-flex justify-content-between">
                <PrimaryButton onClick={props.onConfirm}>{t('generic.yes')}</PrimaryButton>
                <PrimaryButton onClick={props.onCancel}>{t('generic.no')}</PrimaryButton>
            </DialogActions>
        </Dialog>
    )
}
