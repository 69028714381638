import React, { FC, useEffect } from 'react';
import { authSelector, formatDateForDisplay, formatDateTimeForDisplay, getVog, useAppDispatch, Vog, vogSelector } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, Stack } from '@mui/material';
import { LabelValue } from '../../atoms';


export const VogViewDetailContent: FC = () => {
    const dispatch = useAppDispatch();
    const {vogId} = useParams();
    const vogState = useSelector(vogSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);

    useEffect(() => {
        if (vogId) {
            dispatch(getVog({jwtToken: authState.jwtToken!, vogId: vogId}));
        }
    }, [dispatch, vogId, authState.jwtToken]);

    function formatScreeningsProfielen(vog?: Vog) {
        if (vog) {
            const result = [];
            if (vog.specifiekProfielFunctieAspectNr) {
                result.push(<div>{vog.specifiekProfielFunctieAspectNr}. {vog.specifiekFunctieAspectOmschrijving}</div>);
            } else {
                vog.algemeenScreeningsProfielFuncties?.forEach(profile => {
                    result.push(<div>{profile.functieAspectNr}. {profile.functieAspectOmschrijving}</div>);
                })
            }
            return (result);
        }
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={vogState.error !== undefined}/>
            <h2>{t('fields.vog.single.typeDescription')}</h2>
            <MainCard>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <LabelValue label={t('fields.vog.single.emailAddress')} value={vogState.singleItem?.emailAddress}/>
                            <LabelValue label={t('fields.vog.single.afgiftedatum')} value={formatDateTimeForDisplay(vogState.singleItem?.afgiftedatum)}/>
                            <Grid sx={{mt: 4}}>
                                <LabelValue label={t('fields.vog.single.geslachtsnaam')} value={vogState.singleItem?.geslachtsnaam}/>
                            </Grid>
                            <LabelValue label={t('fields.vog.single.tussenvoegsels')} value={vogState.singleItem?.tussenvoegsels}/>
                            <LabelValue label={t('fields.vog.single.voornamen')} value={vogState.singleItem?.voornamen}/>
                            <LabelValue label={t('fields.vog.single.geboortedatum')} value={formatDateForDisplay(vogState.singleItem?.geboortedatum)}/>
                            <LabelValue label={t('fields.vog.single.geboorteplaats')} value={vogState.singleItem?.geboorteplaats}/>
                            <LabelValue label={t('fields.vog.single.geboorteland')} value={vogState.singleItem?.geboorteland}/>
                            <Grid sx={{mt: 4}}>
                                <LabelValue label={t('fields.vog.single.belanghebbendeKvk')} value={vogState.singleItem?.belanghebbendeKvk}/>
                                <LabelValue label={t('fields.vog.single.belanghebbendeNaam')} value={vogState.singleItem?.belanghebbendeNaam}/>
                            </Grid>
                            <Grid sx={{mt: 4}}>
                                <LabelValue label={t('fields.vog.single.doelWaarde')} value={vogState.singleItem?.doelWaarde}/>
                            </Grid>
                            <LabelValue label={t('fields.vog.single.kenmerken')} value={vogState.singleItem?.kenmerken}/>
                            <LabelValue label={t('fields.vog.single.doelOmschrijving')} value={vogState.singleItem?.doelOmschrijving}/>
                            <LabelValue label={t('fields.vog.single.doelFunctie')} value={vogState.singleItem?.doelFunctie}/>

                            {(vogState?.singleItem?.specifiekProfielFunctieAspectNr) &&
                                <LabelValue label={t('fields.vog.single.algemeenScreeningsProfiel')} value={formatScreeningsProfielen(vogState.singleItem)}/>
                            }
                            {(vogState?.singleItem?.specifiekProfielFunctieAspectNr === undefined) &&
                                <LabelValue label={t('fields.vog.single.specifiekeScreeningsProfiel')} value={formatScreeningsProfielen(vogState.singleItem)}/>
                            }
                            <Grid sx={{mt: 4}}>
                                <LabelValue label={t('fields.vog.single.receivedAt')} value={formatDateTimeForDisplay(vogState.singleItem?.receivedAt)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 4}}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )

}

