import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase, Grid } from '@mui/material';
import { SxProps } from '@mui/system';

// project-imports
import LogoCredenco from './LogoCredenco';
import React, { useMemo } from 'react';
import { APP_DEFAULT_PATH } from '../../../config';
import { useSelector } from 'react-redux';
import { tenantConfigSelector } from '../../../state';
import TenantLogo from './TenantLogo';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
    reverse?: boolean;
    isIcon?: boolean;
    sx?: SxProps;
    to?: To;
}

const Logo = ({reverse, isIcon, sx, to}: Props) => {
    const tenantConfig = useSelector(tenantConfigSelector);

    const name = useMemo(() => {
        if (tenantConfig?.name) {
            return (<Grid item pl={1}>
                    {tenantConfig?.name}
                </Grid>
            )
        } else {
            return null;
        }
    }, [tenantConfig?.name]);
    const logo = useMemo(() => {
        if (tenantConfig?.base64EncodedLogo) {
            return (<TenantLogo size="64" base64EncodedLogo={tenantConfig?.base64EncodedLogo!}/>)
        } else {
            return (<LogoCredenco size="64"/>)
        }
    }, [tenantConfig?.base64EncodedLogo]);

    return (
        <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item>
                    {logo}
                </Grid>
                {name}
            </Grid>

        </ButtonBase>
    )

};

export default Logo;
