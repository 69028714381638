import React, { FC } from 'react';
import { t } from 'i18next';
import { Alert } from '@mui/material';

interface Props {
    errorMessage: string | undefined | null
    errorCode?: string | undefined
    show: boolean
}

export const ErrorAlert: FC<Props> = (props) => {
    let messageToShow = ((props.errorCode === undefined) || (props.errorCode === '')) ? props.errorMessage : t('error.errorCodes.' + props.errorCode);
    if (messageToShow && props.show) {
        return (<Alert severity="error" sx={{mb: 2}}>{messageToShow}</Alert>);
    } else {
        return (<span/>);
    }
};
