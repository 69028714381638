import React, { FC, useEffect, useMemo, useState } from 'react';
import { SCREENINGS_PROFIEL_TYPE_ALGEMEEN, ScreeningsProfiel, screeningsProfielenSelector } from '../../state';
import { Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import { useSelector } from 'react-redux';


interface Props {
    selectedScreeningProfiles: ScreeningsProfiel[] | undefined;
    onChange: (screeningsProfielen: ScreeningsProfiel[]) => void;
    disabled?: boolean;
}


export const AlgemeenScreeningsProfielCheckboxGroup: FC<Props> = (props) => {
        const [state, setState] = useState<Map<string, boolean>>(new Map());

    let allScreeningsProfiles = useSelector(screeningsProfielenSelector);
    const screeningsProfiles = useMemo(() => {
        if (allScreeningsProfiles) {
            return allScreeningsProfiles.filter(p => p.screeningsProfielType === SCREENINGS_PROFIEL_TYPE_ALGEMEEN)
                .sort((a, b) => {
                    if (b > a) return 1;
                    if (b < a) return -1;
                    return 0;
                })
        }
    }, [allScreeningsProfiles]);

    useEffect(() => {
            if (screeningsProfiles) {
                let allProfiles = new Map();
                screeningsProfiles.forEach((p: ScreeningsProfiel) => {
                        allProfiles.set(p.functieAspectNr, false);
                    }
                );

                setState(allProfiles);
            }
            if (props.selectedScreeningProfiles) {
                let newState = new Map();
                state.forEach(function (value, key) {
                    newState.set(key, false);
                });

                props.selectedScreeningProfiles.forEach((p) => {
                        newState.set(p.functieAspectNr, true);
                    }
                );
                setState(newState);
            }
        // eslint-disable-next-line
        }, [props.selectedScreeningProfiles, screeningsProfiles]
    );


    const getScreeningsProfile = (functieAspectNr: string): ScreeningsProfiel => {
        return screeningsProfiles?.filter(p => p.functieAspectNr === functieAspectNr)[0]!;
    }

    const onSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
        const functieAspectNr = event.target.name;
        const checked = event.target.checked;
        const newMap = new Map();
        state.forEach(function (value, key) {
            newMap.set(key, value);
        });
        newMap.set(functieAspectNr, checked);
        setState(newMap);
        const functieProfielen = Array.from(newMap.entries())
            .filter(entry => entry[1])
            .map(entry => getScreeningsProfile(entry[0]))
        props.onChange(functieProfielen);
    };

    function isChecked(functieAspectNr: string): boolean {
        if (state.get(functieAspectNr)) {
            return state.get(functieAspectNr)!;
        }
        return false;
    }

    return (
        <Grid container>
            {screeningsProfiles?.map((screeningsProfile) => (
                <Grid item xs={12} md={6} key={screeningsProfile.functieAspectNr} sx={{mb: 1}}>
                    <FormControlLabel disabled={props.disabled} value={screeningsProfile.functieAspectNr} control={
                        <Checkbox onChange={onSelectItem} checked={isChecked(screeningsProfile.functieAspectNr!)} name={screeningsProfile.functieAspectNr!}/>
                    }
                                      label={
                                          <Stack
                                              direction="row"
                                              justifyContent="flex-start"
                                              alignItems="flex-start">
                                              <Grid item sx={{mr: 1}}>{screeningsProfile.functieAspectNr}</Grid>
                                              <Grid item>{screeningsProfile.functieAspectOmschrijving}</Grid>
                                          </Stack>
                                      }/>
                </Grid>
            ))}
        </Grid>

    )
};




