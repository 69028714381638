// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, useMediaQuery } from '@mui/material';

// project-imports
import DrawerHeaderStyled from './DrawerHeaderStyled';

import useConfig from '../../../../../hooks/useConfig';

// types
import React from 'react';
import { LAYOUT_CONST } from '../../../../atoms/types/config';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../../../../config';
import Logo from '../../../../atoms/logo/Logo';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : HEADER_HEIGHT,
        width: isHorizontal ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
          paddingLeft: isHorizontal ? {xs: '24px', lg: '0'} : open ? '8px' : 0,
          paddingRight: isHorizontal ? {xs: '24px', lg: '0'} : open ? '8px' : 0
      }}
    >
        <Stack direction="row" spacing={2} style={{width: '100%'}}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid item>
                    <Logo isIcon={!open} sx={{width: open ? 'auto' : 52, height: 'auto'}}/>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item style={{fontWeight:'bolder'}} mt={1}>SmartVOG Administratie</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
