import React from 'react';
import { Grid } from '@mui/material';


interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
}


export const LabelValue: React.FC<Props> = (props) => {
  return (
      <Grid container paddingBottom={1}>
        <Grid item xs={12} sm={6} md={4}>
          {props.label}
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          {props.value}
        </Grid>
      </Grid>
  );
};
