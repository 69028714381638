import React, { FC } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { toNullableDate } from '../../state';
import { MuiBox } from '../atoms';


interface DateInputProps {
    onChange: (date: Date) => void;
    value: Date | undefined;
    disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = (props) => {

    return (
        <MuiBox style={{maxWidth:120}}>
        <MyDatePicker className="form-control"
                      placeholderText="dd-mm-yyyy"
                      selected={toNullableDate(props.value)}
                      onChange={props.onChange}
                      disabled={props.disabled}
                      dateFormat="dd-MM-yyyy"
                      showYearDropdown/>
        </MuiBox>
    );
}

const MyDatePicker = styled(DatePicker)`
    max-width: 100px;
    border: none;
    &:focus-within {
        outline: none;
    }
`;

DateInput.defaultProps = {
    disabled: false
}

