import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    authSelector,
    Employee,
    employeeSelector,
    formatDateForDisplay,
    FunctionProfile,
    functionProfileSelector,
    getEmployees, getFunctionProfiles,
    useAppDispatch,
} from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert, IsTenantAdmin } from '../../molecules';
import { t } from 'i18next';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';


interface Props {
}

export const FunctionProfileListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const functionProfiles = useSelector(functionProfileSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    useEffect(() => {
        dispatch(getFunctionProfiles({jwtToken: authState.jwtToken, page: pagination.pageIndex, pageSize: pagination.pageSize}));
        // eslint-disable-next-line
    }, [authState.jwtToken, pagination.pageIndex, pagination.pageSize]);

    const columns = useMemo<MRT_ColumnDef<FunctionProfile>[]>(
        () => [
            {
                header: t('fields.functionProfile.single.name'),
                accessorKey: 'name',
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: functionProfiles.list,
        rowCount: functionProfiles.totalElements,
        pageCount: functionProfiles.totalPages,
        manualPagination: true,
        onPaginationChange: setPagination,
        enableRowSelection: false,
        enableColumnOrdering: false,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        state: { pagination },
        localization: MRT_Localization_NL,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                navigate('/functionprofiles/edit/' + row.original.id);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        })
    });


    return (
        <Container>
            <h2>{t('fields.functionProfile.plural.typeDescription')}</h2>
            <MaterialReactTable table={table} />
            <IsTenantAdmin>
                <PrimaryButton sx={{mt: 2}} onClick={() => {
                    navigate('/functionprofiles/add');
                }}>{t('generic.add')}</PrimaryButton>
            </IsTenantAdmin>
        </Container>
    )


};
