import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, Employee, employeeSelector, formatDateForDisplay, getEmployees, getFullNameFromEmployee, useAppDispatch, } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert, IsTenantAdmin } from '../../molecules';
import { t } from 'i18next';
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';


interface Props {
}


export const EmployeeListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const employees = useSelector(employeeSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const columns = useMemo<MRT_ColumnDef<Employee>[]>(
        () => [
            {
                header: t('fields.employee.single.fullName'),
                accessorFn: (originalRow) => getFullNameFromEmployee(originalRow),
            },
            {
                header: t('fields.employee.single.emailAddress'),
                accessorKey: 'emailAddress',
            },
            {
                header: t('fields.employee.single.geboortedatum'),
                accessorFn: (originalRow) => formatDateForDisplay(originalRow.geboortedatum),
            },
        ],
        [],
    );
    useEffect(() => {
        dispatch(getEmployees({jwtToken: authState.jwtToken, page: pagination.pageIndex, pageSize: pagination.pageSize}));
        // eslint-disable-next-line
    }, [authState.jwtToken, pagination.pageIndex, pagination.pageSize]);

    const table = useMaterialReactTable({
        columns,
        data: employees.list,
        rowCount: employees.totalElements,
        pageCount: employees.totalPages,
        manualPagination: true,
        onPaginationChange: setPagination,
        enableRowSelection: false,
        enableColumnOrdering: false,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        state: { pagination },
        localization: MRT_Localization_NL,
        muiTableBodyCellProps: ({column, row }) => ({
            onClick: (event) => {
                if (column.id !== 'mrt-row-actions') {
                    navigate('/employees/' + row.original.id);
                }
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
    });


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={employees.error !== undefined}/>
            <h2>{t('fields.employee.plural.typeDescription')}</h2>
            <MaterialReactTable table={table} />
            <IsTenantAdmin>
                <PrimaryButton sx={{mt: 2}} onClick={() => {
                    navigate('/employees/add');
                }}>{t('generic.add')}</PrimaryButton>
            </IsTenantAdmin>
        </Container>
    )


};
