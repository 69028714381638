import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { VogRequestForm } from './VogRequestForm';

export const getVogRequestsByEmployee = createAsyncThunk(
    'vogRequest/getVogRequestsByEmployee', ({jwtToken, employeeId, page, pageSize}: { jwtToken: string | undefined, employeeId: string | undefined, page: number, pageSize: number }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/vogrequests/employee/' + employeeId + '?page=' + page + '&size=' + pageSize, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const getVogRequests = createAsyncThunk(
    'vogRequest/getVogRequests', ({jwtToken, status, page, pageSize}: { jwtToken: string | undefined, status: string, page: number, pageSize: number }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/vogrequests/status/' + status + '?page=' + page + '&size=' + pageSize, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const getVogRequest = createAsyncThunk(
    'vogRequest/getVogRequest', ({jwtToken, vogRequestId}: { jwtToken: string | undefined, vogRequestId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/vogrequests/' + vogRequestId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const saveVogRequest = createAsyncThunk(
    'vogRequest/saveVogRequest', ({jwtToken, vogRequestForm}: { jwtToken: string | undefined, vogRequestForm: VogRequestForm }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const body = VogRequestForm.toTransportFormat(vogRequestForm);
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        return axios.post('/manage/vogrequests', body, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const resendShareVogEmail = createAsyncThunk(
    'vogRequest/resendShareVogEmail', ({jwtToken, vogRequestId}: { jwtToken: string | undefined, vogRequestId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.post('/manage/vogrequests/' + vogRequestId + '/resendVogShareEmail', {}, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const updateVogRequestStatus = createAsyncThunk(
    'vogRequest/updateVogRequestStatus', ({jwtToken, vogRequestId, newStatus, vogRequestForm}: { jwtToken: string | undefined, vogRequestId: string | undefined, newStatus: string, vogRequestForm?: VogRequestForm | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const body = (vogRequestForm) ? VogRequestForm.toTransportFormat(vogRequestForm) : {};
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };
        return axios.post('/manage/vogrequests/' + vogRequestId + '/status/' + newStatus, body, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
