import React, { FC } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { t } from 'i18next';
import { parseISO } from 'date-fns'
import moment from 'moment-timezone';
import { MuiBox } from '../atoms';

interface DateInputProps {
    onChange: (date: Date) => void;
    value: Date | undefined;
    disabled?: boolean;
}

export const DateTimeInput: FC<DateInputProps> = (props) => {
    const date = (typeof props.value === 'string') ? parseISO(props.value) : props.value;

    function onChange(date: Date) {
        props.onChange(
            moment(date).tz("Europe/Amsterdam").toDate()
        );
    }

    return (
        <MuiBox>
        <MyDatePicker className="form-control"
                      placeholderText="dd-mm-yyyy hh:mm"
                      selected={date}
                      disabled={props.disabled}
                      onChange={onChange}
                      dateFormat="dd-MM-yyyy HH:mm"
                      showYearDropdown
                      timeInputLabel={t('generic.time') + ":"}
                      showTimeInput/>
        </MuiBox>
    );
}

const MyDatePicker = styled(DatePicker)`
  max-width: 200px;
  border: none;
  &:focus-within {
    outline: none;
  }
`;

DateTimeInput.defaultProps = {
    disabled: false
}

