import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';
import axios from 'axios';
import { bearerAuth } from '../auth';
import { FunctionProfileForm } from './FunctionProfileForm';

export const getFunctionProfiles = createAsyncThunk(
        'functionProfile/getFunctionProfiles', ({jwtToken, page, pageSize}: {jwtToken: string | undefined, page: number, pageSize: number}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));

            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.get('/manage/functionProfiles?page=' + page + '&size=' + pageSize, config)
                .then(response => {
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
export const getFunctionProfile = createAsyncThunk(
    'functionProfile/getFunctionProfile', ({jwtToken, functionProfileId}: { jwtToken: string | undefined, functionProfileId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.get('/manage/functionProfiles/' + functionProfileId, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
export const saveFunctionProfile = createAsyncThunk(
    'functionProfile/saveFunctionProfile', ({jwtToken, functionProfileForm}: { jwtToken: string | undefined, functionProfileForm: FunctionProfileForm }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const body = {
            id: functionProfileForm.id,
            name: functionProfileForm.name,
            screeningsProfiles: functionProfileForm.screeningsProfiles
        }
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        return axios.post('/manage/functionProfiles', body, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);
