import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { globalSlice, GlobalState } from "./slices/global/GlobalStateSlice";
import { TypedUseSelectorHook, useDispatch, useSelector as useAppSelector } from "react-redux";
import { authenticationSlice, AuthenticationState } from "./slices/authentication/AuthenticationSlice";
import { authSlice, AuthState, employeeSlice, EmployeeState, menuSlice, staticDataSlice, StaticDataState, vogRequestSlice, VogRequestState, vogSlice, VogState } from './slices';
import { MenuProps } from '../components/atoms/types/menu';
import { signupSlice, SignupState } from "./slices/signup";
import { functionProfileSlice, FunctionProfileState } from './slices/functionProfile';
import { taskSlice, TaskState } from './slices/task/TaskSlice';

export interface ApplicationState {
    globalState: GlobalState;
    authState: AuthState;
    authentication: AuthenticationState;
    signupState: SignupState;
    staticDataState: StaticDataState;
    vogRequestState: VogRequestState;
    vogState: VogState;
    employeeState: EmployeeState;
    functionProfileState: FunctionProfileState;
    menuState: MenuProps;
    taskState: TaskState;
}

const rootReducer = combineReducers<ApplicationState>({
    authState: authSlice.reducer,
    globalState: globalSlice.reducer,
    authentication: authenticationSlice.reducer,
    signupState: signupSlice.reducer,
    staticDataState: staticDataSlice.reducer,
    vogRequestState: vogRequestSlice.reducer,
    vogState: vogSlice.reducer,
    employeeState: employeeSlice.reducer,
    functionProfileState: functionProfileSlice.reducer,
    menuState: menuSlice.reducer,
    taskState: taskSlice.reducer,
});

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// });

// @ts-ignore
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export const useSelector: TypedUseSelectorHook<ApplicationState> = useAppSelector;


