import React, { FC } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { Task } from '../../state';

interface Props {
    task: Task,
    onActionSelected?: (task: Task) => void;
}

export const TaskActionButton: FC<Props> = (props) => {
    const {t} = useTranslation();

    return (<PrimaryButton className="mr-1 mb-1"
                           onClick={() => {
                               props.onActionSelected?.(props.task);
                           }}>{t('fields.task.taskAction.' + props.task.taskType)}</PrimaryButton>);
};

const Label = styled.div`
    width: 90px;
`;



